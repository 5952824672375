import { useEditorDataContext } from "../../context/EditorDataProvider";
import styled from "styled-components";

const HelperStyled = styled.div`
    pointer-events: none;
    position: relative;
    z-index: 5;
`;
const Helper = () => {
    const { getCurrentPageData } = useEditorDataContext();
    const page = getCurrentPageData();
    const helperData = page?.helper;
    const hideHelper = page?.hideHelper;
    if (!helperData || hideHelper) return <></>;
    return (
        <HelperStyled
            dangerouslySetInnerHTML={{ __html: helperData }}
        ></HelperStyled>
    );
};

export default Helper;
