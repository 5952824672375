import React, { useContext } from "react";
import Grid from "../Grid/GridContainer";
import {
    StyledSideBarContainer,
    StyledSideBarInner,
    StyledSideBarSubTitle,
} from "../style-components/styleComponents";
import { defaultColors } from "../../utils/utils";
import styled from "styled-components";
import { useEditorDataContext } from "../../context/EditorDataProvider";
import { ResetButton } from "../buttons/buttons";
import { FoldMenuContext } from "../../context/FoldMenuProvider";
import useIsMobile from "../../hooks/useIsMobile";

interface IStyledButtonColor {
    color: string;
}

const StyledButtonColor = styled.button<IStyledButtonColor>`
    width: 34px;
    height: 34px;
    margin: 0 auto;
    ${({ color }) =>
        color !== "none" ?
            `background-color:${color};`
        :   `
      background-color:white;
      &:after{
          content:'';
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          height: 2px;
          background-color:red;
          transform: translateY(-50%) rotate(-45deg);
      }
  `}
    border:var(--background-text-box) 2px solid;
    cursor: pointer;
    border-radius: 5px;
    position: relative;
    &:hover {
        border-color: var(--primary-color-brand);
    }
`;

const StyledAddNewColor = styled(ResetButton)`
    width: 34px;
    height: 34px;
    margin: 0 auto;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-image: conic-gradient(
        from 0.25turn,
        #e0201a,
        #ee3f16 0.08turn,
        #ff6128 0.15turn,
        #fda206 0.25turn,
        #ffed00 0.33turn,
        #dff051 0.4turn,
        #17ab70 0.49turn,
        #1a8f9c 0.58turn,
        #005aff 0.66turn,
        #5c4ece 0.76turn,
        #582c86 0.84turn,
        #ff1b71 0.92turn,
        #e0201a
    );
    span {
        color: var(--white);
        box-shadow: 0 -6px 20px 0 rgba(0, 0, 0, 0.1);
        font-size: 36px;
        font-weight: bold;
    }
`;

interface IColors {
    handleChangeColor: (color: string) => void;
    handleAddNewColor: () => void;
    transparent?: boolean;
}
const Colors = ({
    handleChangeColor,
    handleAddNewColor,
    transparent = true,
}: IColors) => {
    const { editorData, updateGlobalSettings } = useEditorDataContext();
    const { setOpenFoldMenu }: any = useContext(FoldMenuContext);
    const isMobile = useIsMobile();
    const { chosenColors = [] } = editorData;

    const itemsRow = 6;

    const handleChange = (color: string) => handleChangeColor(color);

    const handleOnClick = (color: string) => {
        handleChange(color);
        if (isMobile) {
            setOpenFoldMenu(false);
        }
    };

    return (
        <StyledSideBarContainer>
            <StyledSideBarInner>
                <Grid itemsPerRow={itemsRow}>
                    <StyledAddNewColor
                        onClick={() => {
                            updateGlobalSettings({
                                chosenColors: ["#000", ...chosenColors],
                            });
                            handleAddNewColor();
                        }}
                    >
                        <span>+</span>
                    </StyledAddNewColor>

                    {transparent && (
                        <StyledButtonColor
                            onClick={() => handleOnClick("none")}
                            color={"none"}
                        />
                    )}

                    {chosenColors.map((color: string, index: number) => (
                        <StyledButtonColor
                            onClick={() => handleOnClick(color)}
                            key={`customer_colors_${index}`}
                            color={color}
                        />
                    ))}
                </Grid>
            </StyledSideBarInner>
            <StyledSideBarInner>
                <StyledSideBarSubTitle>צבעים</StyledSideBarSubTitle>
                <Grid itemsPerRow={itemsRow}>
                    {defaultColors.map((color, index) => (
                        <StyledButtonColor
                            onClick={() => handleOnClick(color)}
                            key={`default_colors_${index}`}
                            color={color}
                        />
                    ))}
                </Grid>
            </StyledSideBarInner>
        </StyledSideBarContainer>
    );
};

export default Colors;
