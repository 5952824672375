import { useCallback, useEffect, useRef } from "react";

import { useEditorDataContext } from "../../../context/EditorDataProvider";
import Tooltip, { ETooltipPlace } from "../../tool-tip/Tooltip";
import ToolbarBtn from "../../buttons/ToolbarBtn";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg";
import { nanoid } from "nanoid";
import { StyledTitleToolBar } from "../../style-components/styleComponents";

interface ITrash {
    showToolBarTxt?: boolean;
}
const Trash = ({ showToolBarTxt = true }: ITrash) => {
    const { removeItem } = useEditorDataContext();
    const handleKeyPress = useCallback((e: KeyboardEvent) => {
        if (e.key === "Delete" || (e.metaKey && e.key === "Backspace")) {
            e.preventDefault();
            handleOnTrash();
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPress);
        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [handleKeyPress]);

    const handleOnTrash = async () => removeItem();

    const ref = useRef(null);
    const tooltipId = `trashTooltip-${nanoid()}`;
    const txt = "מחיקה";
    return (
        <>
            <ToolbarBtn
                data-tip
                data-for={tooltipId}
                ref={ref}
                className=""
                onClick={handleOnTrash}
            >
                <TrashIcon />
                {showToolBarTxt && (
                    <StyledTitleToolBar className="hide-desktop">
                        {txt}
                    </StyledTitleToolBar>
                )}
            </ToolbarBtn>
            <Tooltip place={ETooltipPlace.BOTTOM} id={tooltipId} elemRef={ref}>
                {txt}
            </Tooltip>
        </>
    );
};

export default Trash;
