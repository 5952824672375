import { createContext, useCallback, useContext, useState } from "react";
import { usePageContext } from "./PageProvider";
import useGenerateImageSingleColor from "../hooks/useGenerateImageSingleColor";
import LoadingTopSnake from "../components/loading-snake/loading-top-snake";
import { HistoryContext } from "./HistoryProvider";
import { PhotosInCanvasContext } from "./PhotosInCanvasProvider";
import { IContextProviders } from "../interfaces/context-providers.interface";
import {
    IEditorDataItem,
    IEditorDataPage,
    IEditorDataPageItemImage,
    IEditorDataV2,
    TEditorDataItemTypes,
} from "../interfaces/editor-data.interface";
import { EEditorDataItemTypes } from "../enums/editor-data-item-type.enum";
import { FoldMenuContext } from "./FoldMenuProvider";
import useIsMobile from "../hooks/useIsMobile";
import { useAppDispatch } from "../redux/hook";
import { delErrorPerItem } from "../redux/features/errorListPerItemSlice";
import {
    addSnapPointsElement,
    removeSnapPointsElement,
} from "../redux/features/snapPointsSlice";
import { getElementPoints } from "../utils/snaps";
import { useCanvasAlignItem } from "../hooks/useCanvasAlignItem";
import { isImageItem, isTextItem } from "../utils/editor-data";

export enum EUpdateEditorActions {
    ADD_ITEM = "addItem",
    UPDATE_ITEM = "updateItem",
}

interface IEditorDataContext {
    editorData: IEditorDataV2;
    getEditorDataOutPut: () => IEditorDataV2;
    canvasAlignItem: {
        updateAlignCanvas: (item: IEditorDataItem) => void;
        getAlignCanvas: () => { [key: string]: Partial<IEditorDataItem> };
    };
    initData: (data: IEditorDataV2) => void;
    updateEditorData: (
        item: TEditorDataItemTypes,
        action?: EUpdateEditorActions,
        imageReplace?: boolean,
    ) => Promise<IEditorDataV2 | undefined>;
    updateItems: (items: Array<IEditorDataItem>) => void;
    updateFocusElements: (id: string, firstClick?: boolean) => void;
    updateOrderItems: (items: Array<IEditorDataItem>) => void;
    getCurrentPageData: () => IEditorDataPage;
    updateScale: (scale: number) => void;
    updatePageData: (page: number, data: Partial<IEditorDataPage>) => void;
    removeItem: () => void;
    updateGlobalSettings: (settings: Partial<IEditorDataV2>) => void;
}

export const EditorDataContext = createContext<IEditorDataContext | null>(null);

export const EditorDataProvider = ({ children }: IContextProviders) => {
    const { page } = usePageContext();
    const { setOpenFoldMenu }: any = useContext(FoldMenuContext);
    const isMobile = useIsMobile();
    const [editorData, setEditorData] = useState<IEditorDataV2>({
        pages: [
            {
                width: 0,
                height: 0,
                items: [],
                bleed: 0,
                cssWidth: 0,
                cssHeight: 0,
                scale: 1,
            },
        ],
    });
    const { isLoading, mutateAsync } = useGenerateImageSingleColor();
    const { saveHistory }: any = useContext(HistoryContext);
    const { savePhotos }: any = useContext(PhotosInCanvasContext);
    const pageData = editorData.pages[page];
    if (!pageData) return null;

    const dispatch = useAppDispatch();

    const getImageSingleColor = async (item: IEditorDataPageItemImage) => {
        if (item.type !== EEditorDataItemTypes.IMAGE || item.duplicate)
            return item;
        const printColor = pageData.printColor ? pageData.printColor : null;
        if (!printColor) return item;
        const { base64, storageSrc } = item;
        const resp = await mutateAsync({
            storageSrc,
            base64,
            color: printColor,
        });
        item.base64 = `data:image/png;base64,${resp.data.base64}`;
        return item;
    };

    const canvasAlignItem = useCanvasAlignItem(pageData);

    const getCurrentPageData = () => pageData;

    const updateGlobalSettings = useCallback(
        (settings: Partial<IEditorDataV2>) => {
            setEditorData((prevEditorData) => ({
                ...prevEditorData,
                ...settings,
            }));
        },
        [setEditorData],
    );

    interface IUpdatePageData {
        hideHelper?: boolean;
    }

    const updatePageData = (page: number, data: IUpdatePageData) => {
        setEditorData((prevEditorData) => {
            const cpEditorData = { ...prevEditorData };
            cpEditorData.pages = [...cpEditorData.pages];
            cpEditorData.pages[page] = {
                ...(cpEditorData.pages[page] as IEditorDataPage),
                ...data,
            };
            return cpEditorData;
        });
    };

    const getEditorDataOutPut = useCallback((): IEditorDataV2 => {
        const alignCanvas = canvasAlignItem.getAlignCanvas();

        const updatedPages = editorData.pages.map((page) => ({
            ...page,
            items: page.items.map((item) => ({
                ...item,
                alignOnCanvas:
                    alignCanvas[item.id]?.alignOnCanvas || item?.alignOnCanvas,
            })),
        }));

        return {
            ...editorData,
            pages: updatedPages,
        };
    }, [editorData, canvasAlignItem]);

    const value = {
        editorData,
        initData: (data: IEditorDataV2) => setEditorData(data),
        getCurrentPageData,
        canvasAlignItem,
        getEditorDataOutPut,
        removeItem: () => {
            const cpEditorData = { ...editorData };
            if (!cpEditorData.pages[page]) return cpEditorData;
            const pageItems = cpEditorData.pages[page].items;
            const itemsToKeep = (pageItems as IEditorDataItem[]).reduce<
                IEditorDataItem[]
            >((acc, item) => {
                if (item.focus) {
                    dispatch(delErrorPerItem({ id: item.id }));
                    dispatch(
                        removeSnapPointsElement({
                            pageIndex: page,
                            elementId: item.id,
                        }),
                    );
                } else {
                    acc.push(item);
                }
                return acc;
            }, []);

            cpEditorData.pages[page].items = itemsToKeep;
            saveHistory(cpEditorData);
            setEditorData(cpEditorData);
        },

        updateEditorData: async (
            item: TEditorDataItemTypes,
            action: EUpdateEditorActions = EUpdateEditorActions.ADD_ITEM,
            imageReplace = false,
        ): Promise<IEditorDataV2 | undefined> => {
            const cpEditorData = { ...editorData };
            if (!cpEditorData.pages[page]) return cpEditorData;
            let pageItems = [...cpEditorData.pages[page].items];
            const { type } = item ?? {};

            //Add Item
            if (action === EUpdateEditorActions.ADD_ITEM) {
                item = await getImageSingleColor(
                    item as IEditorDataPageItemImage,
                );

                if (isImageItem(item) && item.base64) {
                    savePhotos(item);
                }

                if (type === EEditorDataItemTypes.BACKGROUND) {
                    pageItems = [item, ...pageItems];
                } else {
                    //Remove PlaceHolder.
                    pageItems = pageItems.filter(
                        (pageItem) => pageItem?.placeHolder !== true,
                    );

                    pageItems.push(item);
                }

                if (type !== EEditorDataItemTypes.BACKGROUND) {
                    const snapPoints = getElementPoints(
                        {
                            top: item.top,
                            left: item.left,
                            width: item.width,
                            height: item.height,
                            rotation: item.rotation,
                        },
                        item.id,
                    );
                    dispatch(
                        addSnapPointsElement({
                            pageIndex: page,
                            points: snapPoints,
                        }),
                    );
                }
                if (isMobile) {
                    setOpenFoldMenu(false);
                }
            }

            //Update Item
            else if (action === EUpdateEditorActions.UPDATE_ITEM) {
                const currentItemIndex = pageItems.findIndex(
                    (pageItem) => pageItem.key === item.id,
                );
                if (currentItemIndex === -1) {
                    return;
                }
                if (imageReplace) {
                    item = await getImageSingleColor(
                        item as IEditorDataPageItemImage,
                    );
                }
                pageItems[currentItemIndex] = {
                    ...pageItems[currentItemIndex],
                    ...item,
                };
            }

            //Save.
            if (action === EUpdateEditorActions.ADD_ITEM) {
                saveHistory(cpEditorData);
            }

            cpEditorData.pages[page].items = pageItems;
            setEditorData(cpEditorData);
            return cpEditorData;
        },

        updateItems: (items: Array<IEditorDataItem>) => {
            const cpEditorData = { ...editorData };
            if (!cpEditorData.pages[page]) return cpEditorData;
            const pageItems = cpEditorData.pages[page].items;
            items.forEach((item) => {
                const currentItemIndex = pageItems.findIndex(
                    (pageItem: IEditorDataItem) => pageItem.key === item.id,
                );
                pageItems[currentItemIndex] = {
                    ...pageItems[currentItemIndex],
                    ...item,
                };
            });
            saveHistory(editorData);
            setEditorData(editorData);
        },

        updateOrderItems: (items: IEditorDataItem[]) => {
            const cpEditorData = { ...editorData };
            if (!cpEditorData.pages[page]) return cpEditorData;
            cpEditorData.pages[page].items = items;
            saveHistory(cpEditorData);
            setEditorData(cpEditorData);
        },

        updateFocusElements: (id: string, firstClick = false) => {
            const cpEditorData = { ...editorData };
            if (!cpEditorData.pages[page]) return cpEditorData;

            cpEditorData.pages[page].items.map((item) => {
                //Check is ItemsText.
                item.focus = item.key === id;
                if (isTextItem(item) && item?.editable) {
                    item.editable = false;
                }
                //First Click
                return item;
            });

            setEditorData(cpEditorData);
        },
        //TODO CHECK THIS METHOD.
        updateScale: (scale: number) => {
            setEditorData((prevEditorData) => {
                const cpEditorData = { ...prevEditorData };
                if (!cpEditorData.pages[page]) return cpEditorData;

                cpEditorData.pages[page] = {
                    ...cpEditorData.pages[page],
                    scale: scale,
                };
                return cpEditorData;
            });
        },

        updatePageData,
        updateGlobalSettings,
    };

    return (
        <EditorDataContext.Provider value={value}>
            {isLoading && <LoadingTopSnake />}
            {children}
        </EditorDataContext.Provider>
    );
};

export const useEditorDataContext = () => {
    const context = useContext(EditorDataContext);
    if (!context) {
        throw new Error(
            "useEditorDataContext must be used within a EditorDataProvider",
        );
    }
    return context;
};
