import { nanoid } from "nanoid";
import {
    IEditorDataItem,
    IEditorDataPage,
    IEditorDataPageItemImage,
    IEditorDataPageItemSvg,
    IEditorDataPageItemText,
} from "../interfaces/editor-data.interface";
import { CM2PX } from "./utils";
import { EEditorDataItemTypes } from "../enums/editor-data-item-type.enum";
import { ISideBarTextBlockData } from "../interfaces/side-bar-text-block.interface";
import { EShapes } from "../components/sideBar/foldMenu/elements/listElements";
import recreateSvg from "./svg/recreateSvg";
import { defaultStyleSvg } from "../components/sideBar/foldMenu/elements/FoldElement";

import { EFontStyle, EFontWeight } from "../enums/font-style.enum";
import { ETextAlignment } from "../enums/toolbar.enum";

export const getText = (
    itemProps: ISideBarTextBlockData,
    printColor: string,
): IEditorDataPageItemText => {
    const id = nanoid();
    const {
        text,
        fontSize,
        width,
        fontStyle,
        fontWeight,
        top,
        left,
        firstClick = true,
    } = itemProps;
    return {
        key: id,
        id: id,
        content: "",
        placeHolderText: text,
        focus: true,
        firstClick,
        font: `Assistant`,
        fontSize,
        color: printColor ? printColor : `#000000`,
        type: EEditorDataItemTypes.TEXT,
        width,
        height: 200,
        top,
        left,
        rotation: 0,
        fontStyle,
        fontWeight,
        borderWidth: 0,
        editable: true,
        templateCss: JSON.stringify({
            fontFamily: `Assistant`,
            fontSize: `90`,
            fontWeight,
            fontStretch: `normal`,
            fontStyle: `normal`,
            lineHeight: `normal`,
            letterSpacing: `normal`,
            textAlign: `center`,
            color: `#000000`,
        }),
        borderColor: printColor ? printColor : `#000000`,
    };
};

export const getSimpleObjectText = (
    printColor: string | null,
): IEditorDataPageItemText => {
    const id = nanoid();
    return {
        key: id,
        id: id,
        content: "",
        placeHolderText: "",
        focus: true,
        firstClick: false,
        font: `Assistant`,
        fontSize: 24,
        color: printColor ? printColor : `#000000`,
        type: EEditorDataItemTypes.TEXT,
        width: 300,
        height: 100,
        top: 0,
        left: 0,
        rotation: 0,
        fontWeight: EFontWeight.REGULAR_WEIGHT,
        fontStyle: EFontStyle.REGULAR,
        borderWidth: 0,
        editable: true,
        borderColor: printColor ? printColor : `#000000`,
        align: ETextAlignment.RIGHT,
    };
};

export interface IImageItem {
    width: number;
    height: number;
    base64: string;
    storageSrc?: string;
    focus?: boolean;
}
export const getImage = (
    page: IEditorDataPage,
    item: IImageItem,
): IEditorDataPageItemImage => {
    const printColor = page.printColor ? page.printColor : null;
    const id = nanoid();
    const pageWidth = CM2PX(page.width);
    const pageHeight = CM2PX(page.height);

    const img = new Image();
    img.src = item.storageSrc ? item.storageSrc : item.base64;
    const resolution = { width: img.naturalWidth, height: img.naturalHeight };
    let { width, height } = item;

    const origW = width,
        origH = height;

    //Add Width and Height.

    if (origW > pageWidth) {
        width = pageWidth;
        height = (origH / origW) * width;
    } else if (origH > pageHeight) {
        height = pageHeight;
        width = (origW / origH) * height;
    }

    const left = pageWidth / 2 - width / 2;
    const top = pageHeight / 2 - height / 2;

    return {
        ...item,
        rotation: 0,
        dimensions: JSON.stringify({ width, height }),
        type: EEditorDataItemTypes.IMAGE,
        key: id,
        id,
        top,
        left,
        width,
        height,
        borderWidth: 0,
        resolution,
        borderColor: printColor ? printColor : `#000000`,
    } as IEditorDataPageItemImage;
};

export interface ISvgObject {
    svg: string;
    shape?: EShapes;
}
export const getSvgObject = (
    page: IEditorDataPage,
    item: ISvgObject,
): IEditorDataPageItemSvg => {
    const id = nanoid();

    const pageWidth = CM2PX(page.width);
    const pageHeight = CM2PX(page.height);

    let width = 100;
    let height = 100;

    if (height > pageHeight) {
        width = (width / height) * pageHeight;
        height = pageHeight;
        const svg = recreateSvg(item as IEditorDataPageItemSvg, {
            width,
            height,
        });
        if (svg) item = { ...item, svg };
    }
    /**/

    const left = pageWidth / 2 - width / 2;
    const top = pageHeight / 2 - height / 2;

    return {
        ...item,
        id,
        key: id,
        rotation: 0,
        type: EEditorDataItemTypes.SVG,
        top,
        left,
        width,
        height,
        borderWidth: defaultStyleSvg.strokeWidth,
        focus: true,
    };
};

export const borderBox = 2;

export function isContentBox(item: IEditorDataItem) {
    if (item === undefined) return false;
    return (
        !!item?.borderWidth ||
        (item.type === EEditorDataItemTypes.IMAGE && !!item?.cropElement) ||
        item.type === EEditorDataItemTypes.TEXT
    );
}
