import { createContext, useEffect, useState } from "react";
import { IContextProviders } from "../interfaces/context-providers.interface";
import { useEditorDataContext } from "./EditorDataProvider";
import { IEditorDataPage } from "../interfaces/editor-data.interface";

const initOptions = [
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
    { value: "14", label: "14" },
    { value: "16", label: "16" },
    { value: "18", label: "18" },
    { value: "20", label: "20" },
    { value: "22", label: "22" },
    { value: "24", label: "24" },
    { value: "26", label: "26" },
    { value: "28", label: "28" },
    { value: "36", label: "36" },
    { value: "48", label: "48" },
    { value: "72", label: "72" },
    { value: "80", label: "80" },
    { value: "88", label: "88" },
    { value: "96", label: "96" },
    { value: "104", label: "104" },
    { value: "120", label: "120" },
    { value: "144", label: "144" },
    { value: "180", label: "180" },
    { value: "210", label: "210" },
    { value: "320", label: "320" },
    { value: "400", label: "400" },
    { value: "500", label: "500" },
    { value: "720", label: "720" },
    { value: "1000", label: "1000" },
    { value: "2000", label: "2000" },
];

interface IFontSizes {
    value: string;
    label: string;
}

interface IFontSizesContext {
    fontSizes: IFontSizes[];
}

export const FontSizesContext = createContext<IFontSizesContext | null>(null);

const FontSizesProvider = ({ children }: IContextProviders) => {
    const { editorData } = useEditorDataContext();
    const [fontSizes, setFontSizes] = useState<IFontSizes[]>([]);

    useEffect(() => {
        if (
            editorData &&
            editorData.pages.length > 0 &&
            fontSizes &&
            fontSizes.length === 0
        ) {
            const newFontSizes: any = [];
            editorData.pages.forEach((item: IEditorDataPage, index: number) => {
                const { maxFontSize, minFontSize } = item || {};

                let filteredOptions: IFontSizes[] = [];
                if (minFontSize) {
                    filteredOptions = initOptions.filter((option) => {
                        const value = parseInt(option.value);
                        return value >= minFontSize;
                    });
                }
                if (maxFontSize) {
                    const sizes =
                        filteredOptions.length === 0 ?
                            initOptions
                        :   filteredOptions;
                    filteredOptions = sizes.filter((option) => {
                        const value = parseInt(option.value);
                        return value <= maxFontSize;
                    });
                }
                return newFontSizes.push(
                    filteredOptions.length === 0 ?
                        initOptions
                    :   filteredOptions,
                );
            });
            setFontSizes(newFontSizes);
        }
    }, [editorData]);

    const value = { fontSizes };
    return (
        <FontSizesContext.Provider value={value}>
            {children}
        </FontSizesContext.Provider>
    );
};

export default FontSizesProvider;
