import { useQueryString } from "../context/QueryStringProvider";
import { ECustomerFields } from "../enums/customer-fields.enum";

const useCustomerFields = () => {
    const { customerFields } = useQueryString();
    const customerFieldsArray =
        customerFields ? customerFields.split(",").filter(Boolean) : null;

    const filteredFields = customerFieldsArray?.filter(
        (field: string) => field !== ECustomerFields.LOGO,
    );

    const hasLogoField = customerFieldsArray?.includes(ECustomerFields.LOGO);

    return { customerFieldsArray, filteredFields, hasLogoField };
};

export default useCustomerFields;
