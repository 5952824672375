import React, { useContext, useRef, useState } from "react";
import { usePageContext } from "../../../context/PageProvider";
import {
    EditorDataContext,
    useEditorDataContext,
} from "../../../context/EditorDataProvider";
import Tooltip, { ETooltipPlace } from "../../tool-tip/Tooltip";
import { FoldMenuContext } from "../../../context/FoldMenuProvider";
import {
    IEditorDataItem,
    IEditorDataPageItemText,
} from "../../../interfaces/editor-data.interface";
import { EMenuType } from "../../../enums/menu-type.enum";
import ToolbarBtn from "../../buttons/ToolbarBtn";
import styled from "styled-components";
import { device } from "../../../assets/styles/global";
import useIsMobile from "../../../hooks/useIsMobile";
import ChosenColor from "../../colors/ChosenColors";

interface IStyledColorPick {
    color: string;
}
const StyledColorPick = styled(ToolbarBtn)<IStyledColorPick>`
    display: flex;
    gap: var(--small-gap);
    .txt {
        font-size: var(--extra-small-font-size);
    }
    .color {
        width: 16px;
        height: 16px;
        flex-grow: 0;
        border: solid 1px #d4d5d6;
        border-radius: 100%;
        background-color: ${({ color }) => color};
    }
    gap: var(--extra-small-gap);
    @media (${device.mobile}) {
        .txt {
            font-size: var(--small-font-size);
            order: 2;
        }
    }
`;
const ColorPick = () => {
    const { getCurrentPageData } = useEditorDataContext();
    const page = getCurrentPageData();
    const printColor = page.printColor ? page.printColor : null;
    const items = page.items as IEditorDataPageItemText[];
    const focusedItem = items.find((item: IEditorDataItem) => item.focus);
    const ref = useRef(null);
    const { setOpenFoldMenu, menuType }: any = useContext(FoldMenuContext);
    const active = "color" === menuType ? "active" : "";
    const isMobile = useIsMobile();
    const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false);
    if (!focusedItem) return null;
    return (
        <>
            <StyledColorPick
                color={focusedItem.color}
                ref={ref}
                className={active ? "active" : ""}
                data-tip
                data-for="colorPickerTooltip"
                disabled={!!printColor}
                onClick={(e) => {
                    if (printColor) {
                        return true;
                    }
                    if (isMobile) {
                        setIsOpenDropdown(true);
                    } else {
                        setOpenFoldMenu(true, EMenuType.COLOR);
                    }
                    e.stopPropagation();
                }}
            >
                <span className="txt">צבע</span>
                <div className="color" />
            </StyledColorPick>
            <Tooltip
                place={ETooltipPlace.BOTTOM}
                id="colorPickerTooltip"
                elemRef={ref}
            >
                צבע טקסט
            </Tooltip>
            {isMobile && isOpenDropdown && (
                <ChosenColor onClose={() => setIsOpenDropdown(false)} />
            )}
        </>
    );
};

export default ColorPick;
