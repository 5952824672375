import React, { createContext, useContext, useState } from "react";
import { IContextProviders } from "../interfaces/context-providers.interface";

export interface IPageContext {
    page: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
}

export const PageContext = createContext<IPageContext | undefined>(undefined);

export const PageProvider = ({ children }: IContextProviders) => {
    const [page, setPage] = useState(0);
    const value = { page, setPage };
    return (
        <PageContext.Provider value={value}>{children}</PageContext.Provider>
    );
};

export const usePageContext = () => {
    const context = useContext(PageContext);
    if (!context) {
        throw new Error("usePageContext must be used within a PageProvider");
    }
    return context;
};
