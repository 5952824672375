import React from "react";
import SnapCanvas from "./SnapCanvas";
import { useAppSelector } from "../../redux/hook";
import {
    ISnapPoint,
    ISnapPointsPage,
} from "../../redux/features/snapPointsSlice";
import SnapPointsElement from "./SnapPointsElement";
import { IEditorDataItem } from "../../interfaces/editor-data.interface";
import { useEditorDataContext } from "../../context/EditorDataProvider";
import { borderBox, isContentBox } from "../../utils/editor-items";
import { usePageContext } from "../../context/PageProvider";

const SnapPointsRenderer = () => {
    const { page: pageIndex } = usePageContext();
    const { getCurrentPageData } = useEditorDataContext();
    const page = getCurrentPageData();
    const items = page.items;
    const focusedItem = items.find((item: IEditorDataItem) => item.focus);
    const snapData = useAppSelector((state) => state.snapPoints);
    const { pages: snapPoints, isMove } = snapData;
    const snapPointsPage =
        snapPoints ? (snapPoints[pageIndex] as ISnapPointsPage) : undefined;
    if (!isMove) return null;
    if (!focusedItem) return null;
    const border = isContentBox(focusedItem) ? borderBox : 0;
    return (
        <>
            {snapPointsPage?.snapPointsCanvas?.map(
                (snapPoint: ISnapPoint, index: number) => (
                    <SnapCanvas key={`${page}_snap_${index}`} {...snapPoint} />
                ),
            )}
            <SnapPointsElement
                snapPoints={snapPointsPage?.snapPointElements ?? {}}
                currentId={focusedItem?.id}
                border={border}
            />
        </>
    );
};

export default React.memo(SnapPointsRenderer);
