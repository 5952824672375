import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { useQueryString } from "../../../../context/QueryStringProvider";
import {
    StyledSideBarContainer,
    StyledSideBarTitle,
} from "../../../style-components/styleComponents";
import LogoField from "./LogoField";
import CustomerFieldsList from "./CustomerFieldsList";
import useCustomerFields from "../../../../hooks/useCustomerFields";

const FoldGraphicDesigner = () => {
    const { t } = useTranslation();
    const { isGraphicDesigner } = useQueryString();
    const { customerFieldsArray, filteredFields, hasLogoField } =
        useCustomerFields();

    if (!isGraphicDesigner) return null;

    const title =
        customerFieldsArray ? t("customerFields") : t("customerFieldsEmpty");

    return (
        <StyledSideBarContainer>
            <StyledSideBarTitle>{title}</StyledSideBarTitle>
            {customerFieldsArray && (
                <>
                    {filteredFields && (
                        <CustomerFieldsList fields={filteredFields} />
                    )}
                    {hasLogoField && <LogoField />}
                </>
            )}
        </StyledSideBarContainer>
    );
};

export default memo(FoldGraphicDesigner);
