import React, { memo, useContext, useEffect, useState } from "react";
import LoadingTopSnake from "./loading-snake/loading-top-snake";
import { useEditorDataContext } from "../context/EditorDataProvider";
import { HistoryContext } from "../context/HistoryProvider";
import { PhotosInCanvasContext } from "../context/PhotosInCanvasProvider";
import { useQueryString } from "../context/QueryStringProvider";
import useAutoSave from "../hooks/useAutoSave";
import useIndexedDB, { EIndexDbSettings } from "../hooks/useIndexedDB";
import { initEditorData } from "../utils/editor-data/init-data";
import { migrateEditorDataV2 } from "../utils/editor-data/migrate-editor-data";
import { waitInitDataForPostMessage } from "../utils/editor-data";

interface IInitEditor {
    children: React.ReactNode;
}

const InitEditor = ({ children }: IInitEditor) => {
    const { initData } = useEditorDataContext();
    const [isLoading, setIsLoading] = useState(true);
    const { saveHistory }: any = useContext(HistoryContext);
    const { savePhotos }: any = useContext(PhotosInCanvasContext);
    const {
        endpointInit,
        autoSaveId,
        clearSavedAutoData,
        setClearSavedAutoData,
    } = useQueryString();
    const { getDataByKey, deleteData } = useIndexedDB(
        EIndexDbSettings.EDITOR_DATA,
        [],
    );

    useAutoSave();

    useEffect(() => {
        (async () => {
            let initialData = migrateEditorDataV2(initEditorData, savePhotos);
            const isInIframe = window.parent !== window;

            let existingData = null;

            if (autoSaveId && !clearSavedAutoData) {
                existingData = await getDataByKey(autoSaveId);
            }
            if (autoSaveId && clearSavedAutoData) {
                await deleteData(autoSaveId);
                setClearSavedAutoData(false);
            }

            const postMessageEditorData =
                endpointInit || !isInIframe ? null : (
                    await waitInitDataForPostMessage()
                );

            if (existingData?.editorData) {
                initialData = migrateEditorDataV2(
                    existingData.editorData,
                    savePhotos,
                );
            } else if (endpointInit) {
                const resp = await fetch(endpointInit);
                const json = await resp.json();
                initialData = migrateEditorDataV2(json, savePhotos);
            } else if (postMessageEditorData) {
                initialData = migrateEditorDataV2(
                    postMessageEditorData,
                    savePhotos,
                );
            }
            initData(initialData);
            saveHistory(initialData);
            setIsLoading(false);
        })();
    }, []);

    if (isLoading) return <LoadingTopSnake />;

    return <> {children} </>;
};

export default memo(InitEditor);
