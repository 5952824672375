import React, { useCallback, useContext, useEffect } from "react";
import styled from "styled-components";
import { IEditorDataItem } from "../../../interfaces/editor-data.interface";
import {
    EUpdateEditorActions,
    useEditorDataContext,
} from "../../../context/EditorDataProvider";
import { useAppSelector } from "../../../redux/hook";

interface IMaskCropProps {
    item: IEditorDataItem | undefined;
}
const StyledMaskCrop = styled.div`
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
`;

const MaskCrop = ({ item }: IMaskCropProps) => {
    const { updateEditorData } = useEditorDataContext();
    const printAreaClientRect = useAppSelector(
        (state) => state.printAreaClientRect,
    );

    const handleOnClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            if (!item) return false;
            updateEditorData(
                { ...item, isFixCropped: false },
                EUpdateEditorActions.UPDATE_ITEM,
            );
        },
        [item],
    );

    return (
        <StyledMaskCrop
            style={{
                width: printAreaClientRect.w + printAreaClientRect.x * 2,
                height: printAreaClientRect.h + printAreaClientRect.y * 2,
                left: -printAreaClientRect.x,
                top: -printAreaClientRect.y,
            }}
            className="Jonathan"
            onClick={handleOnClick}
        />
    );
};

export default MaskCrop;
