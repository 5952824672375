import React from "react";

export const GraphicDesignerIcon = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.75 25.227a1.022 1.022 0 1 0 2.045 0c0-3.609 2.078-6.804 5.317-8.315a7.11 7.11 0 0 0 7.787-.008 9.33 9.33 0 0 1 2.609 1.816 9.145 9.145 0 0 1 2.697 6.507 1.022 1.022 0 1 0 2.045 0c0-3.003-1.17-5.828-3.296-7.953a11.324 11.324 0 0 0-2.424-1.825 7.125 7.125 0 0 0 1.628-4.54c0-3.948-3.212-7.159-7.159-7.159-3.947 0-7.16 3.212-7.16 7.16 0 1.717.61 3.295 1.622 4.53a11.182 11.182 0 0 0-5.711 9.787zM14.999 5.795a5.12 5.12 0 0 1 5.114 5.114 5.12 5.12 0 0 1-5.114 5.114 5.12 5.12 0 0 1-5.114-5.114A5.12 5.12 0 0 1 15 5.795z"
                fill="#1D1D1B"
            />
        </svg>
    );
};
