import { useEffect } from "react";
import { useEditorDataContext } from "../context/EditorDataProvider";
import { useQueryString } from "../context/QueryStringProvider";
import useIndexedDB, { EIndexDbSettings } from "./useIndexedDB";

const useAutoSave = () => {
    const { getEditorDataOutPut } = useEditorDataContext();
    const { autoSaveId, clearSavedAutoData } = useQueryString();
    const { addData, updateData, getDataByKey } = useIndexedDB(
        EIndexDbSettings.EDITOR_DATA,
        [],
    );

    useEffect(() => {
        if (autoSaveId && !clearSavedAutoData) {
            const interval = setInterval(async () => {
                try {
                    const existingData = await getDataByKey(autoSaveId);
                    const editorData = getEditorDataOutPut();
                    if (existingData) {
                        const { editorData: existingEditorData } = existingData;

                        if (
                            JSON.stringify(existingEditorData) !==
                            JSON.stringify(editorData)
                        ) {
                            updateData({ id: autoSaveId, editorData });
                        }
                    } else {
                        addData({ id: autoSaveId, editorData });
                    }
                } catch (error) {
                    console.error("Error during auto-save:", error);
                }
            }, 2000);
            return () => clearInterval(interval);
        }
    }, [autoSaveId, getEditorDataOutPut, getDataByKey, updateData, addData]);
};

export default useAutoSave;
