import { useEffect, useState } from "react";
import { Range } from "react-range";
import { useEditorDataContext } from "../../context/EditorDataProvider";
import styled from "styled-components";

import { device } from "../../assets/styles/global";
import useIsMobile from "../../hooks/useIsMobile";

const ContainerRangeStyled = styled.div`
    @media (${device.desktop}) {
        position: absolute;
        right: 0;
        border: 1px solid var(--main-border-color);
    }
    direction: ltr;
    background-color: var(--white);
    border-radius: var(--main-border-radius);
    padding: var(--small-gap);
    gap: var(--small-gap);
    width: 320px;
    display: flex;
    align-items: center;
    height: 50px;
    box-sizing: border-box;
    justify-content: space-between;
    @media (${device.mobile}) {
        width: 100%;
        height: inherit;
        flex-direction: column;
        justify-content: start;
        padding-bottom: var(--large-gap);
        & > div:first-of-type {
            order: 2;
        }
    }
`;

const TotalRangeStyled = styled.div`
    border: 1px solid var(--main-border-color);
    border-radius: var(--main-border-radius);
    padding: var(--extra-small-gap) var(--small-gap);
    max-width: 50px;
    width: 100%;
    display: flex;
    @media (${device.mobile}) {
        margin-left: auto;
    }
`;

export enum EItemProp {
    BORDER_WIDTH = "borderWidth",
    OPACITY = "opacity",
    FONT_SIZE = "fontSize",
}
interface IRangeStyled {
    min: number;
    max: number;
    onChange: (values: number[]) => void;
    itemProp: EItemProp;
    defaultValue?: number;
    symbol?: string;
    inputEditable?: boolean;
}

const RangeStyled = ({
    min,
    max,
    onChange,
    itemProp,
    defaultValue = 0,
    symbol = "",
    inputEditable = false,
}: IRangeStyled) => {
    const { getCurrentPageData } = useEditorDataContext();
    const page = getCurrentPageData();
    const items = page.items;
    const focusedItem = items.find((item) => item.focus) as any;
    if (!focusedItem) return null;

    const itemPropValue =
        focusedItem[itemProp] ? focusedItem[itemProp] : defaultValue;
    const [tempValue, setTempValue] = useState(itemPropValue.toString());
    const isMobile = useIsMobile();

    useEffect(() => {
        setTempValue(itemPropValue.toString());
    }, [itemPropValue]);

    const handleInputChange = (e: any) => {
        setTempValue(e.target.value);
    };

    const handleKeyDown = (e: any) => {
        if (e.key === "Enter") {
            e.preventDefault();
            const newValue = Number(tempValue);
            if (newValue >= min) {
                onChange([newValue]);
            }
        }
    };

    const handleBlur = (e: any) => {
        if (!isMobile) {
            return false;
        }
        const newValue = Number(e.target.value);
        if (newValue >= min) {
            onChange([newValue]);
        }
    };

    const rangeValue = itemPropValue > max ? max : itemPropValue;

    return (
        <ContainerRangeStyled>
            <Range
                step={1}
                min={min}
                max={max}
                values={[rangeValue]}
                onChange={(values: any) => onChange(values)}
                renderTrack={({ props, children }) => {
                    const percent = ((rangeValue - min) / (max - min)) * 100;
                    return (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                height: "10px",
                                width: "100%",
                                borderRadius: "var(--main-border-radius)",
                                backgroundColor: "var(--main-border-color)",
                                position: "relative",
                            }}
                        >
                            <div
                                style={{
                                    position: "absolute",
                                    height: "10px",
                                    width: `${percent}%`,
                                    borderRadius: "var(--main-border-radius)",
                                    backgroundColor: "#b1dfda",
                                }}
                            />
                            {children}
                        </div>
                    );
                }}
                renderThumb={({ props }) => {
                    const { key, ...restProps } = props;
                    return (
                        <div
                            key={key}
                            {...restProps}
                            style={{
                                ...props.style,
                                height: "22px",
                                width: "22px",
                                boxShadow: "var(--main-shadow)",
                                borderRadius: "100%",
                                backgroundColor: "var(--white)",
                            }}
                        />
                    );
                }}
            />
            <TotalRangeStyled
                style={{
                    justifyContent: symbol ? "space-between" : "center",
                }}
            >
                {symbol && <span style={{ color: "#acb0b5" }}>{symbol}</span>}
                {!inputEditable && <span>{itemPropValue}</span>}
                {inputEditable && (
                    <input
                        type="number"
                        min={min}
                        max={max}
                        value={tempValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        onFocus={(e) => e.target.select()}
                        onBlur={handleBlur}
                        inputMode="decimal"
                        style={{
                            width: "100%",
                            textAlign: "center",
                            border: 0,
                            padding: 0,
                            backgroundColor: "transparent",
                            outline: "none",
                        }}
                    />
                )}
            </TotalRangeStyled>
        </ContainerRangeStyled>
    );
};

export default RangeStyled;
