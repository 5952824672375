import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import he from "./locales/he/translation.json";

i18n.use(initReactI18next)
    .init({
        lng: "he",
        fallbackLng: "he",
        interpolation: {
            escapeValue: false,
        },
        resources: {
            he: {
                translation: he,
            },
        },
    })
    .catch((error) => console.error("Failed to initialize i18n", error));

export default i18n;
