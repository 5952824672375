import React, { useMemo, useRef } from "react";
import {
    IEditorDataItem,
    IEditorDataPage,
} from "../interfaces/editor-data.interface";
import { ECanvasAlignItem } from "../enums/canvas-align-item.enum";
import { CM2PX } from "../utils/utils";
import { borderBox, isContentBox } from "../utils/editor-items";

export const useCanvasAlignItem = (page: IEditorDataPage) => {
    const alignItemRef = useRef<{ [key: string]: Partial<IEditorDataItem> }>(
        {},
    );

    const dimensions = useMemo(() => {
        return {
            width: CM2PX(page.width),
            height: CM2PX(page.height),
            bleed: page.bleed ? CM2PX(page.bleed) : 0,
        };
    }, [page]);

    const updateAlignCanvas = (item: IEditorDataItem) => {
        const { id, top, left, width: elemW, height: elemH } = item;
        const { width, height, bleed } = dimensions;
        const border = isContentBox(item) ? borderBox : -borderBox;

        const alignments: ECanvasAlignItem[] = [];

        //Alignment top.
        if (top === bleed) {
            alignments.push(ECanvasAlignItem.ALIGN_TOP);
        }

        if (top + elemH + border === height - bleed) {
            alignments.push(ECanvasAlignItem.ALIGN_BOTTOM);
        }

        if (left === bleed) {
            alignments.push(ECanvasAlignItem.ALIGN_LEFT);
        }

        // Alignment right.
        if (left + elemW + border === width - bleed) {
            alignments.push(ECanvasAlignItem.ALIGN_RIGHT);
        }

        if (left + elemW / 2 === width / 2) {
            alignments.push(ECanvasAlignItem.ALIGN_CENTER);
        }

        if (top + elemH / 2 === height / 2) {
            alignments.push(ECanvasAlignItem.ALIGN_HORIZONTAL);
        }

        alignItemRef.current[id] = {
            alignOnCanvas: alignments,
        };
    };

    const getAlignCanvas = () => {
        return alignItemRef.current;
    };

    return {
        updateAlignCanvas,
        getAlignCanvas,
    };
};
