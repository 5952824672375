import { nanoid } from "nanoid";
import { useState } from "react";
import { SketchPicker } from "react-color";
import styled from "styled-components";
import { useEditorDataContext } from "../../context/EditorDataProvider";
import { device } from "../../assets/styles/global";

const StyledColorPicker = styled(SketchPicker)`
    .flexbox-fix:nth-child(3) {
        display: none !important;
    }
`;
const StyledWrapInput = styled.div`
    direction: ltr;
    display: flex;
    align-items: center;
    gap: var(--small-gap);
    margin-top: var(--small-gap);
    label {
        font-size: var(--small-font-size);
    }
    input {
        border: solid 1px #d4d5d6;
        height: 40px;
        max-width: 210px;
        width: 100%;
        border-radius: var(--main-border-radius);
        font-size: var(--main-font-size);
        outline: none;
        padding: 0 var(--small-gap);
        box-sizing: border-box;
        &:focus {
            border: solid 1px #d4d5d6;
        }
        @media (${device.mobile}) {
            max-width: 100%;
            height: 38px;
        }
    }
`;

interface IColorPicker {
    onColorChange: (color: string) => void;
    defaultColor: string;
}

const ColorPicker = ({ defaultColor, onColorChange }: IColorPicker) => {
    const [color, setColor] = useState<string>(defaultColor);
    const { editorData, updateGlobalSettings } = useEditorDataContext();
    const { chosenColors = [] } = editorData;
    const customStyles = {
        default: {
            picker: {
                padding: 0,
                backgroundColor: "transparent",
                border: "none",
                width: "100%",
                boxShadow: "none",
            },
            saturation: {
                borderRadius: "var(--main-border-radius)",
                paddingBottom: "45%",
                marginBottom: "var(--small-gap)",
            },
        },
    };

    const id = nanoid();

    const handleChange = (color: string) => {
        chosenColors[0] = color;
        updateGlobalSettings({
            chosenColors,
        });
        setColor(color);
        onColorChange(color);
    };

    return (
        <div>
            <StyledColorPicker
                presetColors={[]}
                disableAlpha={true}
                color={color}
                onChange={(color) => handleChange(color.hex)}
                styles={customStyles}
            />
            <StyledWrapInput>
                <label htmlFor={`hex_${id}`}>HEX</label>
                <input
                    id={`hex_${id}`}
                    onChange={(e) => handleChange(e.target.value)}
                    type="text"
                    value={color}
                />
            </StyledWrapInput>
        </div>
    );
};

export default ColorPicker;
