import React, { useContext, useRef, useState } from "react";

import ToolbarBtn from "../../buttons/ToolbarBtn";
import styled from "styled-components";
import { useEditorDataContext } from "../../../context/EditorDataProvider";
import Tooltip, { ETooltipPlace } from "../../tool-tip/Tooltip";
import { FoldMenuContext } from "../../../context/FoldMenuProvider";
import {
    IEditorDataItem,
    IEditorDataPageItemSvg,
} from "../../../interfaces/editor-data.interface";
import { EMenuType } from "../../../enums/menu-type.enum";
import { EInnerMenu, IToggleInnerMenu } from "../ToolBar";
import RangeStyled, { EItemProp } from "../../range/RangeStyled";
import { EEditorDataItemTypes } from "../../../enums/editor-data-item-type.enum";
import updateStyleSvg from "../../../utils/svg/updateStyleSvg";
import { defaultStyleSvg } from "../../sideBar/foldMenu/elements/FoldElement";
import recreateSvg from "../../../utils/svg/recreateSvg";
import DropdownMenu from "../../dropdown-menu/DropdownMenu";
import { ReactComponent as BorderIcon } from "../../../assets/icons/border.svg";
import useIsMobile from "../../../hooks/useIsMobile";
import BorderColor from "../../colors/BorderColor";
import { StyledTitleToolBar } from "../../style-components/styleComponents";

const BorderBox = ({ innerMenu, toggleInnerMenu }: IToggleInnerMenu) => {
    const { updateItems, getCurrentPageData } = useEditorDataContext();
    const page = getCurrentPageData();
    const printColor = page.printColor ? page.printColor : null;
    const items = page.items as IEditorDataPageItemSvg[];
    const focusedItem = items.find((item: IEditorDataItem) => item.focus);
    if (!focusedItem) return null;

    const borderWidth = focusedItem.borderWidth ? focusedItem.borderWidth : 0;
    const borderWidthRef: React.RefObject<any> = useRef();
    const borderColorRef: React.RefObject<any> = useRef();
    const { setOpenFoldMenu, menuType }: any = useContext(FoldMenuContext);
    const active = "borderColor" === menuType ? "active" : "";
    const isMobile = useIsMobile();
    const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false);

    const borderTxt = "רוחב גבול";
    const borderColorTxt = "צבע גבול";

    const showBorderColor = borderWidth !== 0;

    return (
        <>
            {showBorderColor && (
                <>
                    <ToolbarBtn
                        className={active}
                        ref={borderColorRef}
                        data-tip
                        data-for="borderColorTooltip"
                        disabled={borderWidth === 0}
                        onClick={(e) => {
                            if (printColor) {
                                return true;
                            }
                            if (isMobile) {
                                setIsOpenDropdown(true);
                            } else {
                                toggleInnerMenu(false);
                                setOpenFoldMenu(true, EMenuType.BORDER_COLOR);
                            }

                            e.stopPropagation();
                        }}
                    >
                        <StyledBorderColor
                            color={
                                printColor ? printColor : (
                                    focusedItem.borderColor
                                )
                            }
                        />
                        <StyledTitleToolBar className="hide-desktop">
                            {borderColorTxt}
                        </StyledTitleToolBar>
                    </ToolbarBtn>
                    <Tooltip
                        place={ETooltipPlace.BOTTOM}
                        id="borderColorTooltip"
                        elemRef={borderColorRef}
                    >
                        {borderColorTxt}
                    </Tooltip>
                    {isMobile && isOpenDropdown && (
                        <BorderColor onClose={() => setIsOpenDropdown(false)} />
                    )}
                </>
            )}
            <ToolbarBtn
                ref={borderWidthRef}
                data-tip
                data-for="borderWidthTooltip"
                onClick={() =>
                    toggleInnerMenu(
                        innerMenu === EInnerMenu.BORDER_BOX ?
                            false
                        :   EInnerMenu.BORDER_BOX,
                    )
                }
            >
                <BorderIcon />
                <StyledTitleToolBar className="hide-desktop">
                    {borderTxt}
                </StyledTitleToolBar>
            </ToolbarBtn>
            <Tooltip
                place={ETooltipPlace.BOTTOM}
                id="borderWidthTooltip"
                elemRef={borderWidthRef}
            >
                {borderTxt}
            </Tooltip>
            {innerMenu === EInnerMenu.BORDER_BOX && (
                <DropdownMenu
                    triggerRef={borderWidthRef}
                    title={borderTxt}
                    handleOnClose={() => toggleInnerMenu(false)}
                >
                    <RangeStyled
                        min={0}
                        max={10}
                        itemProp={EItemProp.BORDER_WIDTH}
                        onChange={(values) => {
                            updateItems(
                                items.map((item) => {
                                    const { focus, type, borderColor } = item;
                                    if (focus && values[0]) {
                                        item.borderWidth = values[0];
                                        /**SVG**/
                                        if (type === EEditorDataItemTypes.SVG) {
                                            const stroke =
                                                borderColor ? borderColor : (
                                                    defaultStyleSvg.stroke
                                                );
                                            const backgroundColor =
                                                item.backgroundColor ?
                                                    item.backgroundColor
                                                :   "none";
                                            item.svg = updateStyleSvg(
                                                item.svg,
                                                {
                                                    strokeWidth: values[0],
                                                    stroke,
                                                    fill: backgroundColor,
                                                },
                                            );
                                            const shape = recreateSvg(item, {
                                                width: item.width,
                                                height: item.height,
                                            });
                                            if (shape) {
                                                item.svg = shape;
                                            }
                                        }
                                    }
                                    return item;
                                }),
                            );
                        }}
                    />
                </DropdownMenu>
            )}
        </>
    );
};

export default BorderBox;

interface IStyledBorderColor {
    color?: string;
}

const StyledBorderColor = styled.div<IStyledBorderColor>`
    width: 10px;
    height: 10px;
    border: 4px solid ${({ color }) => color};
`;
