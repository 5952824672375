import React from "react";
import { useTranslation } from "react-i18next";
import { StyledSideBarSubTitle } from "../../../style-components/styleComponents";
import PhotoItem from "../../items/photoItem";
import { nanoid } from "nanoid";
import logo from "../../../../assets/images/customer-logo.jpg";
import { ECustomerFields } from "../../../../enums/customer-fields.enum";
import { createCustomerVariable } from "../../../../utils/utils";
const LogoField = () => {
    const { t } = useTranslation();
    const id = nanoid();
    return (
        <>
            <StyledSideBarSubTitle>
                {t("addCustomerFieldLogo")}
            </StyledSideBarSubTitle>
            <PhotoItem
                base64={logo}
                id={id}
                itemImage={{
                    customerField: createCustomerVariable(ECustomerFields.LOGO),
                }}
            />
        </>
    );
};

export default LogoField;
