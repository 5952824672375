import React, { useCallback, useContext, useEffect, useRef } from "react";
import { EUpdateEditorActions } from "../../context/EditorDataProvider";
import { IEditorDataItem } from "../../interfaces/editor-data.interface";
import { HistoryContext } from "../../context/HistoryProvider";
import { duplicateItem } from "../toolbar/tools/Duplicate";
import { useSnapPoints } from "../../hooks/useSnapPoints";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { setIsMove } from "../../redux/features/snapPointsSlice";
import { EEditorDataItemTypes } from "../../enums/editor-data-item-type.enum";

let itemsCopied: any = [];

interface IKeyboardHandlerProps {
    focusedItem: IEditorDataItem | undefined;
    updateEditorData: any;
    page: number;
}

const KeyboardHandler = ({
    page,
    focusedItem,
    updateEditorData,
}: IKeyboardHandlerProps) => {
    const { saveHistory }: any = useContext(HistoryContext);
    const snapData = useAppSelector((state) => state.snapPoints);
    const { pages: snapPoints, isMove } = snapData;
    const snapPointsPage: any = snapPoints[page];
    const dispatch = useAppDispatch();
    const handleSnapPoints = useSnapPoints(page, snapPointsPage, 0);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const handleKeyPress = useCallback(
        async (e: KeyboardEvent) => {
            if (document?.activeElement?.tagName === "BODY") {
                await moveElements(e);
                await copyAndPasteElement(e);
            }
        },
        [moveElements, copyAndPasteElement],
    );

    async function moveElements(e: KeyboardEvent) {
        const allowedKeys = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"];
        if (!focusedItem || focusedItem.lock || !allowedKeys.includes(e.key)) {
            return false;
        }

        if (
            focusedItem.type === EEditorDataItemTypes.TEXT &&
            focusedItem.firstClick
        ) {
            return false;
        }
        const newProps: {
            top?: number;
            left?: number;
        } = {};

        const jump = e.shiftKey ? 10 : 1;
        if (e.key === "ArrowUp") newProps.top = focusedItem.top - jump; // Up
        if (e.key === "ArrowDown") newProps.top = focusedItem.top + jump; // Down
        if (e.key === "ArrowRight") newProps.left = focusedItem.left + jump; // Right
        if (e.key === "ArrowLeft") newProps.left = focusedItem.left - jump; // Left

        if (Object.keys(newProps).length) {
            const updatedItem = { ...focusedItem, ...newProps };

            if (!isMove) dispatch(setIsMove(true));
            const { active } = handleSnapPoints(updatedItem, focusedItem);
            if (active && isMove) {
                if (!timeoutRef.current) {
                    timeoutRef.current = setTimeout(() => {
                        dispatch(setIsMove(false));
                        timeoutRef.current = null;
                    }, 2000);
                }
            }
            const data = updateEditorData(
                updatedItem,
                EUpdateEditorActions.UPDATE_ITEM,
            );
            if (!e.repeat) {
                saveHistory(await data);
            }
        }
    }

    function copyAndPasteElement(e: KeyboardEvent) {
        if (focusedItem && focusedItem.type === "text") {
            return false;
        }
        if (e.key === "c" && (e.ctrlKey || e.metaKey)) {
            e.preventDefault();
            itemsCopied = focusedItem;
        }

        if (e.key === "v" && (e.ctrlKey || e.metaKey)) {
            if (Object.keys(itemsCopied).length) {
                updateEditorData(
                    duplicateItem(itemsCopied),
                    EUpdateEditorActions.ADD_ITEM,
                );
            }
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPress);
        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [handleKeyPress]);

    return null;
};

export default KeyboardHandler;
