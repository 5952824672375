import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createAxiosInstance } from "../../utils/axios/axiosConfig";

export enum EStockImagesStatus {
    IDLE = "idle",
    LOADING = "loading",
    SUCCEEDED = "succeeded",
    FAILED = "failed",
}

interface StockImagesState {
    images: Record<string, { name: string; images: string[]; id: string }>;
    status: EStockImagesStatus;
    error: undefined | string;
}

export enum EStockImagesNames {
    background = "תמונות רקע",
    cliparts = "גרפיקות",
    frames = "מסגרות לתמונה",
    icons = "אייקונים",
    shapes = "קווים וצורות",
}

export const fetchStockImages: any = createAsyncThunk(
    "stockImages/fetchStockImages",
    async (_, thunkAPI) => {
        const state: any = thunkAPI.getState();
        const brandConfig = state.brandConfig.brandConfig.generatePdf;
        const axiosInstance = createAxiosInstance(brandConfig);

        try {
            const response = await axiosInstance.get("/get/stock-images/1.0.0");
            return response.data.stockImages;
        } catch (error) {
            if (error instanceof Error) {
                return thunkAPI.rejectWithValue(error?.message);
            }
        }
    },
);

const initialState: StockImagesState = {
    images: {},
    status: EStockImagesStatus.IDLE,
    error: undefined,
};

const stockImagesSlice = createSlice({
    name: "stockImages",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchStockImages.pending, (state, action) => {
                state.status = EStockImagesStatus.LOADING;
            })
            .addCase(fetchStockImages.fulfilled, (state, action) => {
                state.status = EStockImagesStatus.SUCCEEDED;
                const transformedImages = Object.keys(action.payload).reduce(
                    (acc, key) => {
                        const name =
                            EStockImagesNames[
                                key as keyof typeof EStockImagesNames
                            ];
                        acc[key] = {
                            name: name ?? key,
                            id: key,
                            images: action.payload[key],
                        };
                        return acc;
                    },
                    {} as any,
                );
                state.images = transformedImages;
            })
            .addCase(fetchStockImages.rejected, (state, action) => {
                state.status = EStockImagesStatus.FAILED;
                state.error = action.error.message;
            });
    },
});

export default stockImagesSlice.reducer;
