import React, { useCallback, useContext, useRef } from "react";
import ToolbarBtn from "../../buttons/ToolbarBtn";
import Tooltip, { ETooltipPlace } from "../../tool-tip/Tooltip";
import {
    EUpdateEditorActions,
    useEditorDataContext,
} from "../../../context/EditorDataProvider";
import {
    IEditorDataItem,
    IEditorDataPageItemImage,
} from "../../../interfaces/editor-data.interface";
import { isCropped } from "../../../utils/resizable/crop";
import { ReactComponent as CropIcon } from "../../../assets/icons/crop.svg";
import { ReactComponent as UnCropIcon } from "../../../assets/icons/uncrop.svg";
import { StyledTitleToolBar } from "../../style-components/styleComponents";
import { QueryStringContext } from "../../../context/QueryStringProvider";
import { ECustomerFields } from "../../../enums/customer-fields.enum";
import { createCustomerVariable } from "../../../utils/utils";

const Crop = () => {
    const { updateEditorData, getCurrentPageData } = useEditorDataContext();
    const items = getCurrentPageData().items as IEditorDataItem[];
    const focusedItem = items.find((item) => item.focus);
    const { isGraphicDesigner } = useContext(QueryStringContext);
    const isCustomerFieldGraphicDesigner =
        isGraphicDesigner &&
        focusedItem?.customerField ===
            createCustomerVariable(ECustomerFields.LOGO);

    if (!focusedItem || isCustomerFieldGraphicDesigner) {
        return null;
    }

    const txtCrop = "חיתוך תמונה";
    const txtCropMobile = "חיתוך";
    const txtUnCrop = " ביטול חיתוך תמונה";
    const txtUnCropMobile = "ביטול";
    const ref = useRef(null);
    const unCropRef = useRef(null);
    const active = focusedItem.isFixCropped ? "active" : "";

    const isCrop =
        focusedItem?.cropElement ? isCropped(focusedItem?.cropElement) : false;

    const handleOnClick = useCallback(() => {
        updateEditorData(
            { ...focusedItem, isFixCropped: !focusedItem.isFixCropped },
            EUpdateEditorActions.UPDATE_ITEM,
        );
    }, [focusedItem]);

    const handleOnClickUnCrop = useCallback(() => {
        if (!focusedItem.cropElement) return;
        const { width, height, y, leftDelta } = focusedItem.cropElement;

        const top = y === 0 ? focusedItem.top : focusedItem.top - y;
        const left =
            leftDelta === 0 ? focusedItem.left : focusedItem.left - leftDelta;

        delete focusedItem.cropElement;
        updateEditorData(
            { ...focusedItem, width, height, top, left },
            EUpdateEditorActions.UPDATE_ITEM,
        );
    }, [focusedItem]);

    return (
        <>
            <ToolbarBtn
                className={active}
                ref={ref}
                data-tip
                data-for="cropTooltip"
                onClick={handleOnClick}
            >
                <CropIcon />
                <StyledTitleToolBar className="hide-desktop">
                    {txtCropMobile}
                </StyledTitleToolBar>
            </ToolbarBtn>
            <Tooltip
                place={ETooltipPlace.BOTTOM}
                id="cropTooltip"
                elemRef={ref}
            >
                {txtCrop}
            </Tooltip>
            {isCrop && (
                <>
                    <ToolbarBtn
                        ref={unCropRef}
                        data-tip
                        data-for="unCropTooltip"
                        onClick={handleOnClickUnCrop}
                    >
                        <UnCropIcon />
                        <StyledTitleToolBar className="hide-desktop">
                            {txtUnCropMobile}
                        </StyledTitleToolBar>
                    </ToolbarBtn>
                    <Tooltip
                        place={ETooltipPlace.BOTTOM}
                        id="unCropTooltip"
                        elemRef={unCropRef}
                    >
                        {txtUnCrop}
                    </Tooltip>
                </>
            )}
        </>
    );
};

export default Crop;
