import { useEffect } from "react";
import { useEditorDataContext } from "../../../../context/EditorDataProvider";
import { IEditorDataItem } from "../../../../interfaces/editor-data.interface";
import { EEditorDataItemTypes } from "../../../../enums/editor-data-item-type.enum";
import ChosenColor from "../../../colors/ChosenColors";
import BackgroundImages from "./BackgroundsImages";

const FoldBackgrounds = () => {
    const { updateFocusElements, getCurrentPageData } = useEditorDataContext();
    const items = getCurrentPageData().items;
    const focusedItem = items.find((item: IEditorDataItem) => item.focus);
    const { type } = focusedItem ?? {};
    const showBackgroundImages =
        type !== EEditorDataItemTypes.TEXT && type !== EEditorDataItemTypes.SVG;

    useEffect(() => {
        if (type !== EEditorDataItemTypes.BACKGROUND) {
            updateFocusElements("");
        }
    }, []);

    return (
        <>
            <ChosenColor isFold={true} />
            <div style={{ display: showBackgroundImages ? "block" : "none" }}>
                <BackgroundImages />
            </div>
        </>
    );
};

export default FoldBackgrounds;
