import { allowedOrigins } from "../config/allowed-origins";
import { useQueryString } from "../context/QueryStringProvider";

export const getTargetOrigin = () => {
    const { origin } = useQueryString();
    if (!origin) {
        return false;
    }
    return allowedOrigins.includes(origin) ? origin : false;
};
