import { ReactNode, useMemo } from "react";
import { EInnerMenu, IToggleInnerMenu } from "../ToolBar";
import ToolWithOptions from "../ToolWithOptions";
import { usePageContext } from "../../../context/PageProvider";
import { useEditorDataContext } from "../../../context/EditorDataProvider";
import { CM2PX } from "../../../utils/utils";
import { ReactComponent as AlignRightIcon } from "../../../assets/icons/align_right.svg";
import { ReactComponent as AlignCenterIcon } from "../../../assets/icons/align_center.svg";
import { ReactComponent as AlignLeftIcon } from "../../../assets/icons/align_left.svg";
import { ReactComponent as AlignHorizontalIcon } from "../../../assets/icons/align_horizontal.svg";
import { ReactComponent as AlignTopIcon } from "../../../assets/icons/align_top.svg";
import { ReactComponent as AlignBottomIcon } from "../../../assets/icons/align_bottom.svg";
import { borderBox, isContentBox } from "../../../utils/editor-items";
import { useAppSelector } from "../../../redux/hook";
import { useSnapPoints } from "../../../hooks/useSnapPoints";
import { ECanvasAlignItem } from "../../../enums/canvas-align-item.enum";
import { useTranslation } from "react-i18next";
import { IEditorDataItem } from "../../../interfaces/editor-data.interface";

const ActionIcons = {
    ALIGN_LEFT: <AlignLeftIcon />,
    ALIGN_HORIZONTAL: <AlignHorizontalIcon />,
    ALIGN_RIGHT: <AlignRightIcon />,
    ALIGN_TOP: <AlignTopIcon />,
    ALIGN_CENTER: <AlignCenterIcon />,
    ALIGN_BOTTOM: <AlignBottomIcon />,
};

interface IAlignmentOptions {
    title: string;
    action: ECanvasAlignItem;
    icon: ReactNode;
}

const Alignment = ({ innerMenu, toggleInnerMenu }: IToggleInnerMenu) => {
    const { page } = usePageContext();
    const { updateItems, getCurrentPageData } = useEditorDataContext();
    const pageData = getCurrentPageData();

    const items = pageData.items as IEditorDataItem[];
    const pageWidth = CM2PX(pageData.width);
    const pageHeight = CM2PX(pageData.height);
    const bleed = pageData?.bleed ? CM2PX(pageData.bleed as number) : 0;
    const snapData = useAppSelector((state) => state.snapPoints);
    const { pages: snapPoints } = snapData;
    const snapPointsPage: any = snapPoints[page];
    const handleSnapPoints = useSnapPoints(page, snapPointsPage);
    const { t } = useTranslation();

    const alignmentOptions = useMemo<IAlignmentOptions[]>(
        () => [
            {
                title: t("alignmentOptions.top"),
                action: ECanvasAlignItem.ALIGN_TOP,
                icon: ActionIcons.ALIGN_TOP,
            },
            {
                title: t("alignmentOptions.left"),
                action: ECanvasAlignItem.ALIGN_LEFT,
                icon: ActionIcons.ALIGN_LEFT,
            },
            {
                title: t("alignmentOptions.right"),
                action: ECanvasAlignItem.ALIGN_RIGHT,
                icon: ActionIcons.ALIGN_RIGHT,
            },
            {
                title: t("alignmentOptions.bottom"),
                action: ECanvasAlignItem.ALIGN_BOTTOM,
                icon: ActionIcons.ALIGN_BOTTOM,
            },
            {
                title: t("alignmentOptions.center"),
                action: ECanvasAlignItem.ALIGN_CENTER,
                icon: ActionIcons.ALIGN_CENTER,
            },
            {
                title: t("alignmentOptions.horizontal"),
                action: ECanvasAlignItem.ALIGN_HORIZONTAL,
                icon: ActionIcons.ALIGN_HORIZONTAL,
            },
        ],
        [t],
    );

    const handleOptionsClick = (action: ECanvasAlignItem) => {
        updateItems(
            items.map((item) => {
                const border = isContentBox(item) ? borderBox : -borderBox;
                if (item.focus) {
                    switch (action) {
                        case ECanvasAlignItem.ALIGN_TOP:
                            item.top = 0 + bleed;
                            break;

                        case ECanvasAlignItem.ALIGN_LEFT:
                            item.left = 0 + bleed;
                            break;

                        case ECanvasAlignItem.ALIGN_RIGHT:
                            item.left = pageWidth - item.width - bleed - border;
                            break;

                        case ECanvasAlignItem.ALIGN_CENTER:
                            item.left = pageWidth / 2 - item.width / 2;
                            break;

                        case ECanvasAlignItem.ALIGN_HORIZONTAL:
                            item.left = pageWidth / 2 - item.width / 2;
                            item.top = pageHeight / 2 - item.height / 2;
                            break;

                        case ECanvasAlignItem.ALIGN_BOTTOM:
                            item.top =
                                pageHeight - item.height - bleed - border;
                            break;
                    }

                    handleSnapPoints(item, item);
                }
                return item;
            }),
        );
    };

    const handleClick = () =>
        toggleInnerMenu(
            innerMenu === EInnerMenu.ALIGNMENT ? false : EInnerMenu.ALIGNMENT,
        );

    return (
        <ToolWithOptions
            id="alignment"
            icon={<AlignRightIcon />}
            toolTipText={t("alignment")}
            handleClick={handleClick}
            isInnerMenuOpen={innerMenu === EInnerMenu.ALIGNMENT}
            handleClickOptions={handleOptionsClick}
            handleDropDownOnClose={() => toggleInnerMenu(false)}
            options={alignmentOptions}
        />
    );
};

export default Alignment;
