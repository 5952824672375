import { useEditorDataContext } from "../../../context/EditorDataProvider";
import {
    IEditorDataItem,
    IEditorDataPageItemText,
} from "../../../interfaces/editor-data.interface";
import CustomSelect, {
    IOptionCustomSelect,
} from "../../custom-select/CustomSelect";
import { addFocusToTextBox } from "../../../utils/utils";
import useIsMobile from "../../../hooks/useIsMobile";

const fonts: IOptionCustomSelect[] = [
    {
        label: "Alef",
        value: "Alef",
    },
    {
        label: "Assitant",
        value: "Assistant",
    },

    {
        label: "David Libre",
        value: "David Libre",
    },

    {
        label: "Amatic SC",
        value: "Amatic SC",
    },

    {
        label: "Heebo",
        value: "Heebo",
    },
    {
        label: "Lobster",
        value: "Lobster",
    },
    {
        label: "Pacifico",
        value: "Pacifico",
    },
];

const FontFamily = () => {
    const { updateItems, getCurrentPageData } = useEditorDataContext();
    const items = getCurrentPageData().items as IEditorDataPageItemText[];
    const focusedItem = items.find((item: IEditorDataItem) => item.focus);
    const isMobile = useIsMobile();

    if (!focusedItem) return null;

    return (
        <CustomSelect
            defaultValue={focusedItem.font}
            options={fonts}
            placeHolder="גופן"
            onSelect={(selected) => {
                updateItems(
                    items.map((item: IEditorDataPageItemText) => {
                        if (item.focus) item.font = selected;
                        return item;
                    }),
                );
                if (!isMobile) {
                    addFocusToTextBox();
                }
            }}
        />
    );
};

export default FontFamily;
