import { useCallback } from "react";
import { useAppDispatch } from "../redux/hook";
import {
    ISnapPointsPage,
    setSnapPointsByPage,
} from "../redux/features/snapPointsSlice";
import {
    THRESHOLD,
    getElementPoints,
    getUpdatedSnapPointsCanvas,
    updateAndGetSnapPointsElements,
} from "../utils/snaps";
import { borderBox, isContentBox } from "../utils/editor-items";
import { IEditorDataItem } from "../interfaces/editor-data.interface";
import { IPropsBox } from "../components/editorWidgets/utils/Resizable";

export const useSnapPoints = (
    page: number,
    snapPointsPage: ISnapPointsPage,
    threshold: number = THRESHOLD,
) => {
    const dispatch = useAppDispatch();

    const handleSnapPoints = useCallback(
        (propsBox: IPropsBox, currentItem: IEditorDataItem) => {
            let { left, top } = propsBox;
            const border = isContentBox(currentItem) ? borderBox : -borderBox;
            const {
                closestLeftElement,
                closestTopElement,
                updatedSnapPointElements,
            } = updateAndGetSnapPointsElements(
                { ...propsBox, left, top },
                currentItem.id,
                snapPointsPage?.snapPointElements ?? {},
                threshold,
                border,
            );

            const { newSnapPointsCanvas, closestTop, closestLeft } =
                getUpdatedSnapPointsCanvas(
                    snapPointsPage?.snapPointsCanvas ?? [],
                    { ...propsBox, left, top },
                    border,
                );

            const newTop = closestTopElement ?? closestTop;
            const newLeft = closestLeftElement ?? closestLeft;

            if (newTop !== null) {
                top = newTop;
            }

            if (newLeft !== null) {
                left = newLeft;
            }

            if (newTop !== null || newLeft !== null) {
                updatedSnapPointElements[currentItem.id] = getElementPoints(
                    {
                        ...propsBox,
                        top: newTop ?? top,
                        left: newLeft ?? left,
                    },
                    currentItem.id,
                );
            }

            dispatch(
                setSnapPointsByPage({
                    pageIndex: page,
                    snapPointsCanvas: newSnapPointsCanvas ?? [],
                    snapPointElements: updatedSnapPointElements,
                }),
            );

            return { left, top, active: newTop !== null || newLeft !== null };
        },
        [dispatch, page, snapPointsPage],
    );

    return handleSnapPoints;
};
