import React, { useContext, useRef } from "react";
import { ReactComponent as OpacityIcon } from "../../../assets/icons/opacity.svg";
import ToolbarBtn from "../../buttons/ToolbarBtn";
import styled from "styled-components";
import Tooltip, { ETooltipPlace } from "../../tool-tip/Tooltip";
import { EInnerMenu, IToggleInnerMenu } from "../ToolBar";
import RangeStyled, { EItemProp } from "../../range/RangeStyled";
import { IEditorDataItem } from "../../../interfaces/editor-data.interface";
import { useEditorDataContext } from "../../../context/EditorDataProvider";
import DropdownMenu from "../../dropdown-menu/DropdownMenu";
import { StyledTitleToolBar } from "../../style-components/styleComponents";

const ContainerStyled = styled.div`
    display: flex;
    position: relative;
`;

const Opacity = ({ innerMenu, toggleInnerMenu }: IToggleInnerMenu) => {
    const { updateItems, getCurrentPageData } = useEditorDataContext();
    const items = getCurrentPageData().items;
    const opacityRef: React.RefObject<any> = useRef();

    const txt = "שקיפות";
    return (
        <ContainerStyled>
            <ToolbarBtn
                ref={opacityRef}
                data-tip
                data-for="opacityTooltip"
                onClick={() =>
                    toggleInnerMenu(
                        innerMenu === EInnerMenu.OPACITY ?
                            false
                        :   EInnerMenu.OPACITY,
                    )
                }
            >
                <OpacityIcon />
                <StyledTitleToolBar className="hide-desktop">
                    {txt}
                </StyledTitleToolBar>
            </ToolbarBtn>
            <Tooltip
                place={ETooltipPlace.BOTTOM}
                id="opacityTooltip"
                elemRef={opacityRef}
            >
                {txt}
            </Tooltip>

            {innerMenu === EInnerMenu.OPACITY && (
                <DropdownMenu
                    triggerRef={opacityRef}
                    title={txt}
                    handleOnClose={() => toggleInnerMenu(false)}
                >
                    <RangeStyled
                        symbol="%"
                        defaultValue={100}
                        min={1}
                        max={100}
                        itemProp={EItemProp.OPACITY}
                        onChange={(values) => {
                            updateItems(
                                items.map((item: IEditorDataItem) => {
                                    if (item.focus) item.opacity = values[0];
                                    return item;
                                }),
                            );
                        }}
                    />
                </DropdownMenu>
            )}
        </ContainerStyled>
    );
};

export default Opacity;
