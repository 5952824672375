import React from "react";
import { useCallback, useContext, useState } from "react";
import styled from "styled-components";
import { useEditorDataContext } from "../../../context/EditorDataProvider";
import { ImageDraggedContext } from "../../../context/ImageDraggedProvider";
import { getImage } from "../../../utils/editor-items";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { IEditorDataPageItemImage } from "../../../interfaces/editor-data.interface";

type OptionalEditorDataPageItemImage = Partial<IEditorDataPageItemImage>;
interface IPhotoItem {
    id: string;
    src?: string;
    base64?: string;
    onRemove?: (
        e: React.MouseEvent<HTMLButtonElement>,
        id: string,
        src: string,
    ) => void;
    className?: string;
    itemImage?: OptionalEditorDataPageItemImage;
}

const BoxStyled = styled.div`
    position: relative;
    direction: rtl;
    cursor: pointer;
    button {
        position: absolute;
        top: 5px;
        left: 5px;
        background: white;
        border: none;
        cursor: pointer;
        border-radius: 100%;
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
    img {
        max-width: 100%;
    }
`;

const PhotoItem = ({
    src,
    base64,
    id,
    onRemove,
    className,
    itemImage = {},
}: IPhotoItem) => {
    const [isDragged, setIsDragged] = useState<boolean>(false);
    const { updateEditorData, getCurrentPageData } = useEditorDataContext();
    const page = getCurrentPageData();

    const scale = page.scale ? page.scale : 1;
    const { setImageDragged }: any = useContext(ImageDraggedContext);

    const getImageObject = useCallback(
        (e: any, base64: string, storageSrc: string) => {
            const width = e.target.clientWidth / scale;
            const height = e.target.clientHeight / scale;
            const item = { width, height, base64, focus: true, storageSrc };

            return getImage(page, { ...item, ...itemImage });
        },
        [page, scale],
    );

    const handleOnClick = useCallback(
        (e: React.MouseEvent, base64: string, storageSrc: string) => {
            updateEditorData(getImageObject(e, base64, storageSrc));
            e.stopPropagation();
        },
        [getImageObject, updateEditorData],
    );

    const handleDragStart = useCallback(
        (e: React.DragEvent, base64: string, storageSrc: string) => {
            setIsDragged(true);
            const node = e.target as HTMLElement;
            const rect = node.getBoundingClientRect();
            const offsetXElem = e.clientX - rect.x;
            const offsetYElem = e.clientY - rect.y;
            const currentElem = e.target as HTMLImageElement;
            e.dataTransfer.setDragImage(currentElem, offsetXElem, offsetYElem);
            e.dataTransfer.setData(
                "offsetElem",
                JSON.stringify({ offsetXElem, offsetYElem }),
            );
            e.dataTransfer.setData(
                "editorItem",
                JSON.stringify(getImageObject(e, base64, storageSrc)),
            );
            setImageDragged(true);
        },
        [getImageObject, setImageDragged],
    );

    return (
        <BoxStyled className={`boxStyled ${className}`}>
            <LazyLoadImage
                src={src ?? base64}
                onDragEnd={() => {
                    setIsDragged(false);
                }}
                onDragStart={(e) => handleDragStart(e, base64 ?? "", src ?? "")}
                onClick={(e) => handleOnClick(e, base64 ?? "", src ?? "")}
                style={{ objectFit: "cover" }}
            />

            {!isDragged && onRemove && (
                <button
                    type="button"
                    onClick={(e) => {
                        onRemove(e, id, src ?? "");
                    }}
                >
                    X
                </button>
            )}
        </BoxStyled>
    );
};

export default PhotoItem;
