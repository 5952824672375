import { useEffect } from "react";
import { allowedOrigins } from "../config/allowed-origins";

type MessageHandler<T> = (data: T, event: MessageEvent) => void;

const usePostMessageListener = <T = unknown>(handler: MessageHandler<T>) => {
    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            const origin = `${event.origin}/`;
            if (!allowedOrigins.includes(origin)) {
                return;
            }

            handler(event.data, event);
        };
        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, [handler]);
};

export default usePostMessageListener;
