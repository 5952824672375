import { IEditorDataPageItemSvg } from "../../interfaces/editor-data.interface";
import { EShapes } from "../../components/sideBar/foldMenu/elements/listElements";

interface IParenSizes {
    width: number;
    height: number;
}
const recreateSvg = (
    item: IEditorDataPageItemSvg,
    ParentSizes: IParenSizes,
) => {
    const { shape, svg } = item;
    if (!shape || !svg) return false;

    const { width: parentWidth, height: parentHeight } = ParentSizes;

    const parser = new DOMParser();
    const svgDOM = parser.parseFromString(svg, "image/svg+xml");
    const svgElement = svgDOM.getElementsByTagName("svg")[0];
    if (!svgElement) return false;

    const strokeWidth = parseFloat(svgElement.style.strokeWidth);

    const createRectangle = () => {
        const rectElement = svgDOM.getElementsByTagName("rect")[0];
        if (!rectElement) return false;

        svgElement.setAttribute("width", parentWidth.toString());
        svgElement.setAttribute("height", parentHeight.toString());

        if (parentWidth > 0) {
            rectElement.setAttribute(
                "width",
                (parentWidth - strokeWidth).toString(),
            );
        }
        if (parentHeight > 0) {
            rectElement.setAttribute(
                "height",
                (parentHeight - strokeWidth).toString(),
            );
        }

        const x_and_y = strokeWidth === 0 ? 1 : strokeWidth / 2;
        rectElement.setAttribute("x", x_and_y.toString());
        rectElement.setAttribute("y", x_and_y.toString());
    };

    const createTriangle = () => {
        const polygonElement = svgDOM.getElementsByTagName("polygon")[0];
        if (!polygonElement) return false;
        svgElement.setAttribute("width", parentWidth.toString());
        svgElement.setAttribute("height", parentHeight.toString());
        const x1New = parentWidth / 2;
        const y1New = strokeWidth;

        const x2New = parentWidth - strokeWidth;
        const y2New = parentHeight - strokeWidth / 2;

        const x3New = strokeWidth;
        const y3New = parentHeight - strokeWidth / 2;
        polygonElement.setAttribute(
            "points",
            `${x1New},${y1New} ${x2New},${y2New} ${x3New},${y3New}`,
        );
    };

    const createCircle = () => {
        const ellipseElement = svgDOM.getElementsByTagName("ellipse")[0];
        if (!ellipseElement) return false;
        svgElement.setAttribute("width", parentWidth.toString());
        svgElement.setAttribute("height", parentHeight.toString());

        const cx = parentWidth / 2;
        const cy = parentHeight / 2;
        const rx = cx - strokeWidth;
        const ry = cy - strokeWidth;

        ellipseElement.setAttribute("cx", cx.toString());
        ellipseElement.setAttribute("cy", cy.toString());
        ellipseElement.setAttribute("rx", rx.toString());
        ellipseElement.setAttribute("ry", ry.toString());
    };

    const createHexagon = () => {
        const polygonElement = svgDOM.getElementsByTagName("polygon")[0];
        if (!polygonElement) return false;
        svgElement.setAttribute("width", parentWidth.toString());
        svgElement.setAttribute("height", parentHeight.toString());
        const initialPointX = parentWidth * 0.25;
        const initialPointY = parentHeight * 0.1;
        const x1New = parentWidth - initialPointX;
        const y1New = parentHeight - initialPointY;
        const x2New = initialPointX;
        const y2New = parentHeight - initialPointY;
        const x3New = strokeWidth; //
        const y3New = parentHeight / 2; //
        const x4New = initialPointX;
        const y4New = initialPointY;
        const x5New = parentWidth - initialPointX;
        const y5New = initialPointY;
        const x6New = parentWidth - strokeWidth;
        const y6New = parentHeight / 2;

        polygonElement.setAttribute(
            "points",
            `${x1New},${y1New} ${x2New},${y2New} ${x3New},${y3New} ${x4New},${y4New} ${x5New},${y5New} ${x6New},${y6New}`,
        );
    };

    switch (shape) {
        case EShapes.RECTANGLE:
        case EShapes.ROUND_RECTANGLE:
            createRectangle();
            break;
        case EShapes.TRIANGLE:
            createTriangle();
            break;
        case EShapes.CIRCLE:
            createCircle();
            break;
        case EShapes.HEXAGON:
            createHexagon();
            break;
    }

    return new XMLSerializer().serializeToString(svgElement);
};

export default recreateSvg;
