import { allowedOrigins } from "../../config/allowed-origins";
import { EEditorDataItemTypes } from "../../enums/editor-data-item-type.enum";
import { EPostMessageListener } from "../../enums/post-message-listener.enum";
import {
    IEditorDataItem,
    IEditorDataPageItemImage,
    IEditorDataPageItemSvg,
    IEditorDataPageItemText,
    IEditorDataV2,
} from "../../interfaces/editor-data.interface";

export function isImageItem(
    item: IEditorDataItem | IEditorDataPageItemImage | IEditorDataPageItemSvg,
): item is IEditorDataPageItemImage {
    return item.type === EEditorDataItemTypes.IMAGE;
}

export function isTextItem(
    item: IEditorDataItem | IEditorDataPageItemImage | IEditorDataPageItemSvg,
): item is IEditorDataPageItemText {
    return item.type === EEditorDataItemTypes.TEXT;
}

export function isSvgItem(
    item: IEditorDataItem | IEditorDataPageItemImage | IEditorDataPageItemSvg,
): item is IEditorDataPageItemSvg {
    return item.type === EEditorDataItemTypes.SVG;
}

export const waitInitDataForPostMessage = (
    timeout: number = 10000,
): Promise<IEditorDataV2 | null> => {
    return new Promise((resolve) => {
        const handleMessage = (event: MessageEvent) => {
            const origin = `${event.origin}/`;
            if (!allowedOrigins.includes(origin)) {
                return;
            }
            const { type = false, payload = false } = event.data;
            if (type === EPostMessageListener.INIT_EDITOR_DATA) {
                clearTimeout(timeoutId);
                window.removeEventListener("message", handleMessage);
                resolve(payload);
                return;
            } else {
                resolve(null);
            }
        };

        window.addEventListener("message", handleMessage);
        const timeoutId = setTimeout(() => {
            window.removeEventListener("message", handleMessage);
            resolve(null);
        }, timeout);
    });
};
