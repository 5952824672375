import React, { memo, useContext } from "react";
import { useTranslation } from "react-i18next";
import { StyledSideBarSubTitle } from "../../../style-components/styleComponents";
import SimpleSelect from "../../../inputs/toggle-switch/simple-select/SimpleSelect";
import { useEditorDataContext } from "../../../../context/EditorDataProvider";
import { getSimpleObjectText } from "../../../../utils/editor-items";
import { IEditorDataPageItemText } from "../../../../interfaces/editor-data.interface";
import { createCustomerVariable } from "../../../../utils/utils";

interface ICustomerFieldsListProps {
    fields: string[];
}
const CustomerFieldsList = ({ fields }: ICustomerFieldsListProps) => {
    const { t } = useTranslation();
    const { updateEditorData, getCurrentPageData } = useEditorDataContext();
    const page = getCurrentPageData();
    const printColor = page.printColor ? page.printColor : null;

    if (fields.length === 0) return null;

    const options = fields.map((field) => ({
        label: field,
        value: createCustomerVariable(field),
    }));

    const simpleObjectText = getSimpleObjectText(printColor);
    const handleOnSelect = (option: string) => {
        if (!option) return;
        const textObject: IEditorDataPageItemText = {
            ...simpleObjectText,
            firstClick: false,
            content: option,
            customerField: option,
        };
        updateEditorData(textObject);
    };

    return (
        <>
            <StyledSideBarSubTitle>
                {t("selectCustomerFields")}
            </StyledSideBarSubTitle>

            <SimpleSelect
                options={options}
                handleSelect={(option) => handleOnSelect(option)}
            />
        </>
    );
};

export default memo(CustomerFieldsList);
