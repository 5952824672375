import React, { useEffect } from "react";
import MaskCrop from "./MaskCrop";
import { IEditorDataItem } from "../../../interfaces/editor-data.interface";
import { useQueryString } from "../../../context/QueryStringProvider";
import { useEditorDataContext } from "../../../context/EditorDataProvider";
import { EEditorDataItemTypes } from "../../../enums/editor-data-item-type.enum";

interface EEditorWidget {
    children: React.ReactNode;
    item?: IEditorDataItem;
}

const EditorWidget = ({ children, item }: EEditorWidget) => {
    const { isGraphicDesigner } = useQueryString();
    const isCustomerLocked = !isGraphicDesigner && item?.customerLock;
    const { canvasAlignItem } = useEditorDataContext();

    useEffect(() => {
        if (item && item.type !== EEditorDataItemTypes.BACKGROUND) {
            canvasAlignItem.updateAlignCanvas(item);
        }
    }, [item?.left, item?.top]);
    return (
        <>
            {item?.isFixCropped && <MaskCrop item={item} />}
            <div
                style={{
                    position: "relative",
                    zIndex: item?.isFixCropped ? 101 : 2,
                    ...(isCustomerLocked && { pointerEvents: "none" }),
                }}
                className="editorWidget"
            >
                {children}
            </div>
        </>
    );
};

export default EditorWidget;
