import React, { useCallback } from "react";
import styled from "styled-components";
import {
    ICropBox,
    IEditorDataItem,
} from "../../../interfaces/editor-data.interface";
import { EUpdateEditorActions } from "../../../context/EditorDataProvider";
import useIsMobile from "../../../hooks/useIsMobile";

interface ICropElementProps {
    cropBox: ICropBox;
    children: React.ReactNode;
    updateEditorData: (elem: any, action: any) => void;
    currentItem: IEditorDataItem;
}

interface IInnerElementStyledProps {
    isFixCropped: boolean | undefined;
}
const InnerElementStyled = styled.div<IInnerElementStyledProps>`
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const CropElement = ({
    cropBox,
    children,
    updateEditorData,
    currentItem,
}: ICropElementProps) => {
    const isFixCropped = currentItem?.isFixCropped;
    const handleDoubleClick = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation();
            updateEditorData(
                { ...currentItem, isFixCropped: true },
                EUpdateEditorActions.UPDATE_ITEM,
            );
        },
        [currentItem, updateEditorData],
    );
    const isMobile = useIsMobile();
    return (
        <>
            <InnerElementStyled
                isFixCropped={isFixCropped}
                onDoubleClick={handleDoubleClick}
            >
                <div
                    style={{
                        position: "relative",
                        width: `${cropBox.width}px`,
                        height: `${cropBox.height}px`,
                        transform: `translate(${cropBox.x}px,-${cropBox.y}px)`,
                    }}
                >
                    {children}
                </div>
            </InnerElementStyled>
            {isFixCropped && (
                <div
                    onClick={(e) => {
                        if (isMobile) {
                            return false;
                        }
                        e.stopPropagation();
                        updateEditorData(
                            { ...currentItem, isFixCropped: false },
                            EUpdateEditorActions.UPDATE_ITEM,
                        );
                    }}
                    style={{
                        position: "absolute",
                        zIndex: 1,
                        top: 0,
                        opacity: 0.4,
                        width: `${cropBox.width}px`,
                        height: `${cropBox.height}px`,
                        transform: `translate(${cropBox.x}px,-${cropBox.y}px)`,
                    }}
                >
                    {children}
                </div>
            )}
        </>
    );
};

export default CropElement;
