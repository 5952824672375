import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useEditorDataContext } from "../../../context/EditorDataProvider";

import { IEditorDataItem } from "../../../interfaces/editor-data.interface";
import { EEditorDataItemTypes } from "../../../enums/editor-data-item-type.enum";
import Duplicate from "../tools/Duplicate";
import Trash from "../tools/Trash";
import Lock, { ELockType } from "../tools/Lock";
import { getOppositeScale } from "../../../utils/utils";

interface IStyledMiniToolbar {
    top: number;
    left: number;
    width: number;
    oppositeScale: number;
}
const StyledMiniToolbar = styled.div<IStyledMiniToolbar>`
    position: absolute;
    display: flex;
    background-color: var(--white);

    top: ${({ top }) => `${top}px`};
    left: ${({ left, width }) => `${left + width}px`};
    transform: translate(-50%, 0) scale(${({ oppositeScale }) => oppositeScale});
    z-index: 8;
    border-radius: var(--main-border-radius);
    filter: var(--main-filter-shadow);
    &::after {
        content: "";
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid var(--white);
    }

    svg {
        width: 20px;
        height: 20px;
    }
`;

interface IDimensions {
    width: number;
    height: number;
}

const MiniToolbar = () => {
    const { getCurrentPageData } = useEditorDataContext();
    const page = getCurrentPageData();
    const items = page.items;
    const focusedItem = items.find(
        (item: IEditorDataItem) =>
            item.focus && item.type !== EEditorDataItemTypes.BACKGROUND,
    );
    const isFixCropped = focusedItem?.isFixCropped;
    const scale = page.scale ? page.scale : 1;
    const oppositeScale = getOppositeScale(scale);
    const ref = useRef<HTMLDivElement>(null);
    const [dimensions, setDimensions] = useState<IDimensions>({
        width: 0,
        height: 0,
    });

    const isCustomerLocked = focusedItem?.customerLock;

    useEffect(() => {
        if (ref.current) {
            const { width, height } = ref.current.getBoundingClientRect();
            setDimensions({ width, height });
        }
    }, [page, focusedItem, ref.current]);

    if (!focusedItem || isFixCropped) {
        return null;
    }

    const { top, left, width } = focusedItem;
    const isLocked = focusedItem?.lock || isCustomerLocked;

    const miniToolbarHeightScaled = dimensions.height * oppositeScale;
    const adjustedTop = top - miniToolbarHeightScaled - (10 + 10);

    return (
        <StyledMiniToolbar
            ref={ref}
            oppositeScale={oppositeScale}
            width={width / 2}
            top={adjustedTop}
            left={left}
            className="mini-toolbar"
        >
            {!focusedItem.placeHolder && (
                <>
                    {!isLocked && (
                        <>
                            <Duplicate showToolBarTxt={false} />
                            <Trash showToolBarTxt={false} />
                        </>
                    )}
                    <Lock type={ELockType.CUSTOMER} showToolBarTxt={false} />
                    {!isCustomerLocked && <Lock showToolBarTxt={false} />}
                </>
            )}
            {focusedItem.placeHolder && <Trash />}
        </StyledMiniToolbar>
    );
};

export default MiniToolbar;
