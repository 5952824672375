import { usePageContext } from "../../context/PageProvider";
import { useEditorDataContext } from "../../context/EditorDataProvider";
import ToggleSwitch from "../inputs/toggle-switch/ToggleSwitch";
import { useAppSelector } from "../../redux/hook";
import { useTranslation } from "react-i18next";

const ToggleVisibilityHelper = ({ ...props }) => {
    const { page: pageIndex } = usePageContext();
    const { updatePageData, getCurrentPageData } = useEditorDataContext();
    const page = getCurrentPageData();
    const hideHelper = page?.hideHelper;
    const { brandConfig } = useAppSelector((state) => state.brandConfig);
    const { primaryColor } = brandConfig;
    const handleChange = () => {
        updatePageData(pageIndex, { hideHelper: !hideHelper });
    };
    const { t } = useTranslation();

    return (
        <div {...props}>
            <ToggleSwitch
                color={primaryColor}
                label={t("showPrintBorders")}
                onChange={handleChange}
                checked={!hideHelper}
            />
        </div>
    );
};

export default ToggleVisibilityHelper;
