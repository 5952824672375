import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useEditorDataContext } from "../../context/EditorDataProvider";
import styled from "styled-components";
import useSaveProject, { ESaveProjectTypes } from "../../hooks/useSaveProject";

const InputStyled = styled.input`
    border: 1px solid transparent;
    padding: var(--small-gap);
    font-size: var(--main-font-size);
    background-color: transparent;
    outline: none;
    :hover,
    :focus {
        border-color: var(--primary-color-brand);
    }
`;

const ProjectName = ({ ...props }) => {
    const { editorData, updateGlobalSettings } = useEditorDataContext();

    const [projectName, setProjectName] = useState(
        editorData?.project?.name || "",
    );

    const [debouncedValue, setDebouncedValue] = useState(projectName);
    const { handleOnSaveProject } = useSaveProject();
    const isFirstRender = useRef(true);
    useEffect(() => {
        const handler = setTimeout(() => {
            if (isFirstRender.current) {
                isFirstRender.current = false;
                return;
            }
            updateGlobalSettings({
                project: {
                    ...editorData.project,
                    enabled: true,
                    name: debouncedValue,
                },
            });
        }, 500);
        return () => {
            clearTimeout(handler);
        };
    }, [debouncedValue]);

    useEffect(() => {
        if (!isFirstRender.current) {
            handleOnSaveProject(null, ESaveProjectTypes.SAVE);
        }
    }, [editorData?.project?.name]);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setProjectName(event.target.value);
        setDebouncedValue(event.target.value);
    };
    if (!editorData?.project?.enabled) return null;
    return (
        <>
            <InputStyled
                {...props}
                placeholder={editorData?.project?.placeHolder}
                value={projectName}
                onChange={handleInputChange}
            />
        </>
    );
};

export default ProjectName;
