import { useEditorDataContext } from "../context/EditorDataProvider";
import { IEditorDataItem } from "../interfaces/editor-data.interface";

const useFindItemById = () => {
    const { editorData } = useEditorDataContext();

    const findItemById = (itemId: string) => {
        for (const page of editorData.pages) {
            const foundItem = page.items.find(
                (item: IEditorDataItem) => item.id === itemId,
            );
            if (foundItem) {
                return foundItem;
            }
        }
        return undefined;
    };

    return findItemById;
};

export default useFindItemById;
