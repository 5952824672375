import {
    EUpdateEditorActions,
    useEditorDataContext,
} from "../context/EditorDataProvider";
import { IEditorDataItem } from "../interfaces/editor-data.interface";
import updateStyleSvg from "../utils/svg/updateStyleSvg";
import { EEditorDataItemTypes } from "../enums/editor-data-item-type.enum";
import { isSvgItem, isTextItem } from "../utils/editor-data";

const useColorsLogic = () => {
    const { updateItems, updateEditorData, getCurrentPageData } =
        useEditorDataContext();
    const items = getCurrentPageData().items;
    const bgItem = items.find(
        (item: IEditorDataItem) =>
            item.type === EEditorDataItemTypes.BACKGROUND,
    );
    const defaultColor = "#000";
    const focusedItem = items.find((item) => item.focus);
    let transparent = false;
    let initialColor =
        !bgItem ? defaultColor : bgItem.backgroundColor || defaultColor;

    let title = "רקעים";

    const { backgroundColor, lock } = focusedItem ?? {};
    if (!lock && focusedItem) {
        if (isTextItem(focusedItem)) {
            initialColor = focusedItem.color ? focusedItem.color : defaultColor;
            title = "צבע טקסט";
        } else if (isSvgItem(focusedItem)) {
            title = "צבע האלמנט";
            transparent = true;
            initialColor = backgroundColor ? backgroundColor : defaultColor;
        }
    }
    const handleChangeColor = (color: string) => {
        let updateBackgroundColor = true;
        updateItems(
            items.map((item) => {
                const { focus, svg, borderWidth, lock } = item;
                if (isTextItem(item) && focus && !lock) {
                    updateBackgroundColor = false;
                    item.color = color;
                }
                if (svg && focus && !lock) {
                    updateBackgroundColor = false;
                    item.backgroundColor = color ? color : "none";
                    const stroke = item.borderColor ? item.borderColor : "none";
                    item.svg = updateStyleSvg(svg, {
                        strokeWidth: borderWidth,
                        stroke,
                        fill: item.backgroundColor,
                    });
                }
                return item;
            }),
        );

        if (bgItem && updateBackgroundColor) {
            bgItem.backgroundColor = color;
            bgItem.backgroundSrc = "";
            updateEditorData(bgItem, EUpdateEditorActions.UPDATE_ITEM);
        }
    };

    return { title, initialColor, handleChangeColor, transparent };
};

export default useColorsLogic;
