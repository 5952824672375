import { IEditorDataPageItemText } from "../../../interfaces/editor-data.interface";
import ToolbarBtn from "../../buttons/ToolbarBtn";
import { useEditorDataContext } from "../../../context/EditorDataProvider";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import { addFocusToTextBox } from "../../../utils/utils";
import { StyledTitleToolBar } from "../../style-components/styleComponents";

const Edit = ({ ...props }) => {
    const { updateItems, getCurrentPageData } = useEditorDataContext();
    const items = getCurrentPageData().items as IEditorDataPageItemText[];

    const handleClick = async () => {
        await updateItems(
            items.map((item) => {
                if (item.focus) item.editable = true;
                return item;
            }),
        );

        addFocusToTextBox();
    };

    return (
        <ToolbarBtn {...props} onClick={handleClick}>
            <EditIcon />
            <StyledTitleToolBar>עריכה</StyledTitleToolBar>
        </ToolbarBtn>
    );
};

export default Edit;
