import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface IOptions {
    label: string;
    value: string;
}

interface ISimpleSelect {
    options: IOptions[];
    handleSelect: (value: string) => void;
    selected?: string;
    includeChooseOption?: boolean;
}

const StyledSelect = styled.select`
    width: 100%;
    padding: 0 var(--small-gap);
    box-sizing: border-box;
    border-radius: var(--main-border-radius);
    background-color: var(--white);
    border: solid 1px var(--main-border-color);
    height: 30px;
`;

const SimpleSelect = ({
    options,
    handleSelect,
    selected,
    includeChooseOption = true,
}: ISimpleSelect) => {
    const { t } = useTranslation();
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        handleSelect(selectedValue);
    };

    return (
        <StyledSelect onChange={handleChange} value={selected}>
            {includeChooseOption && <option value="">{t("choose")}</option>}
            {options.map((option) => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </StyledSelect>
    );
};

export default SimpleSelect;
