import { useQuery } from "react-query";
import { nanoid } from "nanoid";
import { generatePdf } from "../endpoints";
import { IEditorDataV2 } from "../interfaces/editor-data.interface";
import { useCallback, useState } from "react";
import { useQueryString } from "../context/QueryStringProvider";
import useCustomAxios from "./useCustomAxios";
import { getTargetOrigin } from "../utils/origin";
import { EPostMessageActions } from "../enums/post-message-actions.enum";

const useGeneratePdf = (data: IEditorDataV2) => {
    const { pdfKey, usePopupPdf } = useQueryString();
    const customAxios = useCustomAxios();
    const [pdfSrc, setPdfSrc] = useState<boolean>(false);
    const origin = getTargetOrigin();

    const handleOnSubmit = useCallback(async () => {
        const params = { ...data, selection_id: nanoid(), pdfKey };
        return await customAxios.post(generatePdf, params);
    }, [customAxios, pdfKey, data]);

    const {
        data: responseData,
        isFetching,
        refetch,
    } = useQuery("generatePdf", handleOnSubmit, {
        enabled: false,
        onSuccess: (data) => {
            if (origin) {
                window.parent.postMessage(
                    {
                        action: EPostMessageActions.FINISH_EDITING,
                        response: data.data,
                    },
                    origin,
                );
            }
            if (usePopupPdf) {
                setPdfSrc(data.data.base64);
            }
        },
    });

    return { data: responseData, isFetching, refetch, pdfSrc, setPdfSrc };
};
export default useGeneratePdf;
