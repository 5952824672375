import React, { useContext, useState } from "react";
import styled from "styled-components";

import {
    EUpdateEditorActions,
    useEditorDataContext,
} from "../../context/EditorDataProvider";
import { CM2PX, createCustomerVariable } from "../../utils/utils";
import { HistoryContext } from "../../context/HistoryProvider";
import { ImageDraggedContext } from "../../context/ImageDraggedProvider";
import Resizable from "./utils/Resizable";
import { IEditorDataPageItemImage } from "../../interfaces/editor-data.interface";
import { QueryStringContext } from "../../context/QueryStringProvider";
import { ECustomerFields } from "../../enums/customer-fields.enum";

interface IImageBox {
    currentItem: IEditorDataPageItemImage;
}
const ImageBox = ({ currentItem }: IImageBox) => {
    const { updateEditorData, getCurrentPageData } = useEditorDataContext();
    const page = getCurrentPageData();
    const { imageDragged, setImageDragged }: any =
        useContext(ImageDraggedContext);
    const [isReplace, setIsReplace] = useState<boolean>(false);
    const { saveHistory }: any = useContext(HistoryContext);
    const printColor = page.printColor ? page.printColor : null;
    const { isGraphicDesigner } = useContext(QueryStringContext);
    const isCustomerFieldGraphicDesigner =
        isGraphicDesigner &&
        currentItem?.customerField ===
            createCustomerVariable(ECustomerFields.LOGO);

    const handleOnDragOver = (e: React.DragEvent) =>
        imageDragged ? setIsReplace(true) : "";

    function handleOnDragEnter(e: React.DragEvent) {
        e.preventDefault();
    }

    function handleOnDragLeave() {
        setIsReplace(false);
    }

    async function handleOnDrop(e: any) {
        /*Replace image on Drop*/
        setImageDragged(false);
        const itemImage = e.dataTransfer.getData("editorItem");
        if (!itemImage) {
            setIsReplace(false);
            return false;
        }

        const newItem = JSON.parse(itemImage);

        const { base64, focus, dimensions, type, storageSrc } = newItem;

        if (type !== "image") {
            return false;
        }

        let { width, height } = JSON.parse(dimensions);
        const origW = width;
        const origH = height;
        const pageHeight = CM2PX(page.height);
        const pageWidth = CM2PX(page.width);

        const isCenter =
            currentItem.top === pageHeight / 2 - currentItem.height / 2 &&
            currentItem.left === pageWidth / 2 - currentItem.width / 2;

        //Height Ratio.
        if (height > pageHeight) {
            height = pageHeight;
            width = (origW / origH) * height;
        }
        //Width Ratio.
        else {
            height = (origH / origW) * currentItem.width;
            width = currentItem.width;
        }

        if (isCenter) {
            currentItem.left = pageWidth / 2 - width / 2;
            currentItem.top = pageHeight / 2 - height / 2;
        }

        e.isReplaced = true;
        setIsReplace(false);
        saveHistory(
            await updateEditorData(
                {
                    ...currentItem,
                    base64,
                    storageSrc,
                    focus,
                    width,
                    height,
                    isReplace: true,
                    placeHolder: false,
                },
                EUpdateEditorActions.UPDATE_ITEM,
                true,
            ),
        );
    }

    return (
        <Resizable
            currentItem={currentItem}
            cropElement={!isCustomerFieldGraphicDesigner}
            {...(isCustomerFieldGraphicDesigner && {
                zoomable: "nw, ne, se, sw",
            })}
        >
            <BoxStyled
                draggable={false}
                isReplace={isReplace}
                onDragEnter={handleOnDragEnter}
                onDragLeave={handleOnDragLeave}
                onDragOver={handleOnDragOver}
                onDrop={handleOnDrop}
                src={
                    currentItem.storageSrc && !printColor ?
                        currentItem.storageSrc
                    :   currentItem.base64
                }
            />
        </Resizable>
    );
};

interface IBoxStyled {
    isReplace: boolean;
}
const BoxStyled = styled.img<IBoxStyled>`
    pointer-events: none;
    width: 100%;
    height: 100%;
    user-select: none;
    -webkit-user-drag: none;
    ${({ isReplace }) => (isReplace ? `opacity:0.3;` : ``)};
`;

export default ImageBox;
