import React, { useContext, useRef, useState } from "react";
import { useEditorDataContext } from "../../../context/EditorDataProvider";
import { usePageContext } from "../../../context/PageProvider";
import {
    IEditorDataItem,
    IEditorDataPageItemText,
} from "../../../interfaces/editor-data.interface";
import { FontSizesContext } from "../../../context/FontSizesProvider";
import CustomInputNumber from "../../custom-input-number/CustomInputNumber";
import useIsMobile from "../../../hooks/useIsMobile";
import ToolbarBtn from "../../buttons/ToolbarBtn";
import { StyledTitleToolBar } from "../../style-components/styleComponents";
import DropdownMenu from "../../dropdown-menu/DropdownMenu";
import RangeStyled, { EItemProp } from "../../range/RangeStyled";

const FontSize = () => {
    const { page: pageIndex } = usePageContext();
    const { updateItems, getCurrentPageData } = useEditorDataContext();
    const page = getCurrentPageData();
    const items = page.items as IEditorDataPageItemText[];
    const focusedItem = items.find((item: IEditorDataItem) => item.focus);
    const { fontSizes: fontSizesContext }: any = useContext(FontSizesContext);
    const fontSizesPage = fontSizesContext[pageIndex];
    const [fontSizes]: any = useState(fontSizesPage);
    const { maxFontSize, minFontSize } = page || {};
    const isMobile = useIsMobile();
    const [isOpenMobileDropdown, setIsOpenMobileDropdown] =
        useState<boolean>(false);

    if (!focusedItem) return null;
    const handleChange = (value: any) => {
        let size = value;
        if (maxFontSize && value > maxFontSize) {
            size = maxFontSize;
        }
        if (minFontSize && value < minFontSize) {
            size = minFontSize;
        }
        updateItems(
            items.map((item: IEditorDataPageItemText) => {
                if (item.focus) item.fontSize = size;
                return item;
            }),
        );
    };

    const title = "גודל";

    return (
        <>
            {!isMobile && (
                <CustomInputNumber
                    min={minFontSize}
                    max={maxFontSize}
                    defaultValue={String(focusedItem.fontSize)}
                    direction="rtl"
                    options={fontSizes}
                    onSelect={(selected) => handleChange(selected)}
                />
            )}
            {isMobile && (
                <>
                    <ToolbarBtn onClick={() => setIsOpenMobileDropdown(true)}>
                        <span>{focusedItem.fontSize}</span>
                        <StyledTitleToolBar>{title}</StyledTitleToolBar>
                    </ToolbarBtn>
                    {isOpenMobileDropdown && (
                        <DropdownMenu
                            title={title}
                            handleOnClose={() => setIsOpenMobileDropdown(false)}
                        >
                            <RangeStyled
                                inputEditable={true}
                                defaultValue={focusedItem.fontSize}
                                min={minFontSize || 8}
                                max={140}
                                itemProp={EItemProp.FONT_SIZE}
                                onChange={(values) => {
                                    handleChange(values);
                                }}
                            />
                        </DropdownMenu>
                    )}
                </>
            )}
        </>
    );
};

export default FontSize;
