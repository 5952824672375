import React, { useRef } from "react";
import ToolbarBtn from "../../buttons/ToolbarBtn";
import { useEditorDataContext } from "../../../context/EditorDataProvider";
import { addFocusToTextBox } from "../../../utils/utils";
import { ReactComponent as RtlIcon } from "../../../assets/icons/direction_rtl.svg";
import { ReactComponent as LtrIcon } from "../../../assets/icons/direction_ltr.svg";
import Tooltip, { ETooltipPlace } from "../../tool-tip/Tooltip";
import { IEditorDataPageItemText } from "../../../interfaces/editor-data.interface";
import { ETextAlignment, ETextDirection } from "../../../enums/toolbar.enum";
import { StyledTitleToolBar } from "../../style-components/styleComponents";
import useIsMobile from "../../../hooks/useIsMobile";

const TextDirection = () => {
    const { updateItems, getCurrentPageData } = useEditorDataContext();
    const items = getCurrentPageData().items as IEditorDataPageItemText[];
    const focusedItem = items.find((item) => item.focus);
    const isMobile = useIsMobile();

    function handleOnDirection(dir: ETextDirection, align: ETextAlignment) {
        updateItems(
            items.map((item) => {
                if (item.focus) {
                    item.direction = dir;
                    if (item.align && item.align !== ETextAlignment.CENTER) {
                        item.align = align;
                    }
                }

                return item;
            }),
        );

        if (!isMobile) {
            addFocusToTextBox();
        }
    }

    const refLtr = useRef(null);
    const refRtl = useRef(null);

    if (!focusedItem) return null;

    return (
        <>
            <ToolbarBtn
                className={
                    focusedItem.direction === ETextDirection.LTR ? "active" : ""
                }
                ref={refLtr}
                data-tip
                data-for="directionLtrTooltip"
                onClick={() =>
                    handleOnDirection(ETextDirection.LTR, ETextAlignment.LEFT)
                }
            >
                <LtrIcon />
                <StyledTitleToolBar className="hide-desktop">
                    לשמאל
                </StyledTitleToolBar>
            </ToolbarBtn>
            <Tooltip
                place={ETooltipPlace.BOTTOM}
                id="directionLtrTooltip"
                elemRef={refLtr}
            >
                כיוון לשמאל
            </Tooltip>

            <ToolbarBtn
                className={
                    (
                        focusedItem.direction === ETextDirection.RTL ||
                        !focusedItem.direction
                    ) ?
                        "active"
                    :   ""
                }
                ref={refRtl}
                data-tip
                data-for="directionRtlTooltip"
                onClick={() =>
                    handleOnDirection(ETextDirection.RTL, ETextAlignment.RIGHT)
                }
            >
                <RtlIcon />
                <StyledTitleToolBar className="hide-desktop">
                    לימין
                </StyledTitleToolBar>
            </ToolbarBtn>
            <Tooltip
                place={ETooltipPlace.BOTTOM}
                id="directionRtlTooltip"
                elemRef={refRtl}
            >
                כיוון לימין
            </Tooltip>
        </>
    );
};

export default TextDirection;
