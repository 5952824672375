import React, { useContext, useRef } from "react";
import ToolbarBtn from "../../buttons/ToolbarBtn";
import Tooltip, { ETooltipPlace } from "../../tool-tip/Tooltip";

import { useEditorDataContext } from "../../../context/EditorDataProvider";
import { IEditorDataItem } from "../../../interfaces/editor-data.interface";
import { ReactComponent as LockIcon } from "../../../assets/icons/lock.svg";
import { ReactComponent as CustomerLockIcon } from "../../../assets/icons/customer-lock.svg";

import { ReactComponent as UnLockIcon } from "../../../assets/icons/unlock.svg";
import { ReactComponent as CustomerUnLockIcon } from "../../../assets/icons/customer-unlock.svg";
import { nanoid } from "nanoid";
import { StyledTitleToolBar } from "../../style-components/styleComponents";
import { useTranslation } from "react-i18next";
import { useQueryString } from "../../../context/QueryStringProvider";

export enum ELockType {
    SIMPLE = "simple",
    CUSTOMER = "customer",
}

interface ILock {
    showToolBarTxt?: boolean;
    type?: ELockType;
}

const Lock = ({ showToolBarTxt = true, type = ELockType.SIMPLE }: ILock) => {
    const padlock = useRef(null);
    const padlockOpen = useRef(null);
    const { updateItems, getCurrentPageData } = useEditorDataContext();
    const items = getCurrentPageData().items;
    const focusedItem = items.find((item) => item.focus);

    const isLocked =
        type === ELockType.SIMPLE ?
            focusedItem?.lock
        :   focusedItem?.customerLock;
    const padlockToolTip = `padlockToolTip-${nanoid()}`;
    const padlockOpenToolTip = `padlockOpenToolTip-${nanoid()}`;
    const { t } = useTranslation();
    const { isGraphicDesigner } = useQueryString();

    if (!isGraphicDesigner && type === ELockType.CUSTOMER) return null;

    const toggleLock = (lockState: boolean) => {
        updateItems(
            items.map((item: IEditorDataItem) => {
                if (item.focus) {
                    if (type === ELockType.SIMPLE) item.lock = lockState;
                    else item.customerLock = lockState;
                }
                return item;
            }),
        );
    };

    return (
        <>
            {isLocked ?
                <ToolbarBtn
                    ref={padlock}
                    data-tip
                    data-for={padlockToolTip}
                    onClick={() => toggleLock(false)}
                >
                    {type === ELockType.SIMPLE ?
                        <LockIcon />
                    :   <CustomerLockIcon />}
                    <StyledTitleToolBar>
                        {t(`componentLock.${type}.unLock`)}
                    </StyledTitleToolBar>
                </ToolbarBtn>
            :   <ToolbarBtn
                    ref={padlockOpen}
                    data-tip
                    data-for={padlockOpenToolTip}
                    onClick={() => toggleLock(true)}
                >
                    {type === ELockType.SIMPLE ?
                        <UnLockIcon />
                    :   <CustomerUnLockIcon />}
                    {showToolBarTxt && (
                        <StyledTitleToolBar className="hide-desktop">
                            {t(`componentLock.${type}.lock`)}
                        </StyledTitleToolBar>
                    )}
                    <Tooltip
                        place={ETooltipPlace.BOTTOM}
                        id={padlockOpenToolTip}
                        elemRef={padlockOpen}
                    >
                        {t(`componentLock.${type}.lockObject`)}
                    </Tooltip>
                </ToolbarBtn>
            }
        </>
    );
};

export default Lock;
