import { useState } from "react";
import { ReactComponent as AlertIcon } from "../../assets/icons/alert.svg";
import styled from "styled-components";
import { IPropsBox } from "../editorWidgets/utils/Resizable";
import { useAppSelector } from "../../redux/hook";
import { IErrorPerItem } from "../../redux/features/errorListPerItemSlice";
import PortalPlayArea from "../canvas/PortalPlayArea";
import { getOppositeScale } from "../../utils/utils";
import { useEditorDataContext } from "../../context/EditorDataProvider";
import { device } from "../../assets/styles/global";

interface IErrorInItemProps {
    propsBox: IPropsBox;
    itemId: string;
}

const StyledWrapIcon = styled.div`
    position: absolute;
    z-index: 2;
    cursor: pointer;
    transform: translate(0, -50%);
`;

const StyledErrorMessage = styled.div`
    max-width: 241.6px;
    width: 100%;
    background-color: var(--white);
    position: absolute;
    filter: var(--main-filter-shadow);
    padding: var(--small-gap);
    border-radius: var(--main-border-radius);
    font-size: 1rem;
    transform: translate(-50%, 0);
    text-align: center;
    z-index: 6;
    :after {
        content: "";
        position: absolute;
        top: -10px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid var(--white);
    }
    @media (${device.mobile}) {
        max-width: 120px;
        padding: var(--extra-small-gap);
    }
`;

const ErrorInItem = ({ propsBox, itemId }: IErrorInItemProps) => {
    const { getCurrentPageData } = useEditorDataContext();
    const page = getCurrentPageData();
    const errorPerItem = useAppSelector(
        (state) => state.errorListPerItem.errorPerItem,
    );
    const { top, left, height, width } = propsBox;
    const errorItem = errorPerItem.find(
        (item: IErrorPerItem) => item.id === itemId,
    );
    const [messageIsOpen, setMessageIsOpen] = useState<boolean>(false);
    const scale = page.scale ? page.scale : 1;
    const oppositeScale = getOppositeScale(scale);

    if (!errorItem) {
        return null;
    }

    const spaceBelowBox = 15 * oppositeScale;

    const iconStyle = {
        top: `${top + height / 2}px`,
        left: `${left + spaceBelowBox}px`,
        transform: `translate(0, -50%) scale(${oppositeScale})`,
    };
    const errorMessageStyle = {
        top: `${top + height + spaceBelowBox * oppositeScale}px`,
        left: `${left + width / 2}px`,
        transform: `translate(-50%, 0) scale(${oppositeScale})`,
    };

    return (
        <>
            <StyledWrapIcon
                style={iconStyle}
                onClick={() => setMessageIsOpen(!messageIsOpen)}
            >
                <AlertIcon />
            </StyledWrapIcon>

            {messageIsOpen && (
                <PortalPlayArea>
                    <StyledErrorMessage style={errorMessageStyle}>
                        {errorItem.errors[0]}
                    </StyledErrorMessage>
                </PortalPlayArea>
            )}
        </>
    );
};

export default ErrorInItem;
