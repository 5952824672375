import React, { createContext, useContext, useState } from "react";
import { updateQueryStringParameter, urlParams } from "../utils/utils";
import { IQueryStringData } from "../interfaces/query-string-data.interface";
import { IContextProviders } from "../interfaces/context-providers.interface";

export const QueryStringContext = createContext<any>(null);

const parseBoolean = (value: string | null): boolean => {
    if (typeof value === "string") {
        return value.toLowerCase() === "true";
    }
    return Boolean(value);
};

const QueryStringProvider = ({ children }: IContextProviders) => {
    const params = new URLSearchParams(urlParams);

    const [clearSavedAutoData, setClearSavedAutoData] = useState<boolean>(
        parseBoolean(params.get("clearSavedAutoData")),
    );

    const [storageToken, setStorageToken] = useState<string | null>(
        params.get("storageToken"),
    );

    const value: IQueryStringData = {
        storageToken,
        setStorageToken: (token) => setStorageToken(token),
        storageApi: params.get("storageApi"),
        endpointInit: params.get("endpointInit"),
        origin: params.get("origin"),
        usePopupPdf: parseBoolean(params.get("usePopupPdf")),
        pdfKey: parseBoolean(params.get("pdfKey")),
        autoSaveId: params.get("autoSave"),
        clearSavedAutoData,
        isGraphicDesigner: parseBoolean(params.get("isGraphicDesigner")),
        setClearSavedAutoData: (value: boolean) => {
            updateQueryStringParameter("clearSavedAutoData", String(value));
            setClearSavedAutoData(value);
        },
        customerFields: params.get("customerFields"),
    };

    return (
        <QueryStringContext.Provider value={value}>
            {children}
        </QueryStringContext.Provider>
    );
};

export const useQueryString = () => {
    const context = useContext(QueryStringContext);
    if (!context) {
        throw new Error(
            "useQueryString must be used within a QueryStringProvider",
        );
    }
    return context;
};

export default QueryStringProvider;
