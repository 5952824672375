import { useCallback } from "react";
import { useEditorDataContext } from "../context/EditorDataProvider";
import { getTargetOrigin } from "../utils/origin";
import { EPostMessageActions } from "../enums/post-message-actions.enum";

export enum ESaveProjectTypes {
    SAVE = "save",
    SAVE_AS = "saveAs",
    EMPTY = "empty",
}

const useSaveProject = () => {
    const { getEditorDataOutPut } = useEditorDataContext();
    const origin = getTargetOrigin();

    const handleOnSaveProject = useCallback(
        (e: React.MouseEvent | null, type: ESaveProjectTypes) => {
            const response = getEditorDataOutPut();
            if (origin) {
                window.parent.postMessage(
                    {
                        action: EPostMessageActions.SAVE_PROJECT,
                        response,
                        type,
                    },
                    origin,
                );
            }
        },
        [getEditorDataOutPut, origin],
    );

    return {
        handleOnSaveProject,
    };
};

export default useSaveProject;
