import FullColors from "./FullColors";
import { useEditorDataContext } from "../../context/EditorDataProvider";
import updateStyleSvg from "../../utils/svg/updateStyleSvg";

interface IBorderColor {
    onClose?: () => void;
}

const BorderColor = ({ onClose }: IBorderColor) => {
    const { updateItems, getCurrentPageData } = useEditorDataContext();
    const items = getCurrentPageData().items;
    const defaultColor = "#000";
    const focusedItem = items.find((item) => item.focus);
    if (!focusedItem) return null;
    const initColor =
        focusedItem.borderColor ? focusedItem.borderColor : defaultColor;

    const handleChangeColor = (color: string) => {
        updateItems(
            items.map((item) => {
                const { focus, svg, borderWidth } = item;
                if (focus) {
                    item.borderColor = color;
                }
                if (svg) {
                    const fill =
                        item.backgroundColor ? item.backgroundColor : "none";
                    const stroke = item.borderColor ? item.borderColor : "none";
                    item.svg = updateStyleSvg(svg, {
                        strokeWidth: borderWidth,
                        stroke,
                        fill,
                    });
                }
                return item;
            }),
        );
    };

    return (
        <FullColors
            onClose={() => {
                if (onClose) onClose();
            }}
            title={"צבע גבול"}
            initialColor={initColor}
            handleChangeColor={(color) => handleChangeColor(color)}
        ></FullColors>
    );
};

export default BorderColor;
