import React, { useContext } from "react";
import { createPortal } from "react-dom";
import { usePageContext } from "../../context/PageProvider";

interface IModal {
    children: React.ReactNode;
}

const PortalPlayArea = ({ children }: IModal) => {
    const { page } = usePageContext();
    const portalElement = document.getElementById(`portal_page_${page}`);

    if (portalElement) {
        return createPortal(<>{children}</>, portalElement);
    } else {
        return null;
    }
};

export default PortalPlayArea;
