import { useRef } from "react";
import { useEditorDataContext } from "../../../context/EditorDataProvider";
import ToolbarBtn from "../../buttons/ToolbarBtn";
import { ReactComponent as TextAlignRightIcon } from "../../../assets/icons/text_align_right.svg";
import { ReactComponent as TextAlignLeftIcon } from "../../../assets/icons/text_align_left.svg";
import { ReactComponent as TextAlignCenterIcon } from "../../../assets/icons/text_align_center.svg";

import Tooltip, { ETooltipPlace } from "../../tool-tip/Tooltip";
import { addFocusToTextBox } from "../../../utils/utils";
import { IEditorDataPageItemText } from "../../../interfaces/editor-data.interface";
import { ETextAlignment, ETextDirection } from "../../../enums/toolbar.enum";
import { StyledTitleToolBar } from "../../style-components/styleComponents";
import useIsMobile from "../../../hooks/useIsMobile";

const TextAlignment = () => {
    const { updateItems, getCurrentPageData } = useEditorDataContext();
    const items = getCurrentPageData().items as IEditorDataPageItemText[];
    const focusedItem = items.find((item) => item.focus);
    const isMobile = useIsMobile();
    if (!focusedItem) return null;
    function handleOnAlignment(align: ETextAlignment) {
        updateItems(
            items.map((item) => {
                if (item.focus) {
                    item.align = align;
                    if (align === ETextAlignment.CENTER) {
                        item.direction = ETextDirection.EMPTY;
                    }
                }
                return item;
            }),
        );
        if (!isMobile) {
            addFocusToTextBox();
        }
    }
    const ref = useRef(null);

    return (
        <>
            {(focusedItem.align === ETextAlignment.CENTER ||
                !focusedItem.align) && (
                <>
                    <ToolbarBtn
                        ref={ref}
                        data-tip
                        data-for="textAlign"
                        onClick={() => handleOnAlignment(ETextAlignment.RIGHT)}
                    >
                        <TextAlignCenterIcon />
                        <StyledTitleToolBar className="hide-desktop">
                            לאמצע
                        </StyledTitleToolBar>
                    </ToolbarBtn>
                    <Tooltip
                        place={ETooltipPlace.BOTTOM}
                        elemRef={ref}
                        id="textAlign"
                    >
                        יישור לאמצע
                    </Tooltip>
                </>
            )}

            {focusedItem.align === ETextAlignment.RIGHT && (
                <>
                    <ToolbarBtn
                        ref={ref}
                        data-tip
                        data-for="textAlign"
                        onClick={() => handleOnAlignment(ETextAlignment.LEFT)}
                    >
                        <TextAlignRightIcon />
                        <StyledTitleToolBar className="hide-desktop">
                            לימין
                        </StyledTitleToolBar>
                    </ToolbarBtn>
                    <Tooltip
                        place={ETooltipPlace.BOTTOM}
                        elemRef={ref}
                        id="textAlign"
                    >
                        יישור לימין
                    </Tooltip>
                </>
            )}

            {focusedItem.align === ETextAlignment.LEFT && (
                <>
                    <ToolbarBtn
                        ref={ref}
                        data-tip
                        data-for="textAlign"
                        onClick={() => handleOnAlignment(ETextAlignment.CENTER)}
                    >
                        <TextAlignLeftIcon />
                        <StyledTitleToolBar className="hide-desktop">
                            לשמאל
                        </StyledTitleToolBar>
                    </ToolbarBtn>
                    <Tooltip
                        place={ETooltipPlace.BOTTOM}
                        elemRef={ref}
                        id="textAlign"
                    >
                        יישור לשמאל
                    </Tooltip>
                </>
            )}
        </>
    );
};

export default TextAlignment;
