import { isImageItem } from ".";
import {
    IEditorData,
    IEditorDataPage,
    IEditorDataV2,
    ISharedWithAllPages,
} from "../../interfaces/editor-data.interface";
import { getImage } from "../editor-items";
import { CM2POINTS, CM2PX } from "../utils";

const processPageItems = (
    page: IEditorDataPage,
    savePhotos: (item: any) => void,
): IEditorDataPage => ({
    ...page,
    items: page.items.map((item) => {
        if (!item.id && isImageItem(item)) {
            item = getImage(page, item);
        }
        //Save Photos.
        if (isImageItem(item) && item.base64) {
            savePhotos(item);
        }
        return item;
    }),
});

const applyDimensionConversions = (page: IEditorDataPage) => ({
    ...page,
    widthPoints: CM2POINTS(page.width),
    heightPoints: CM2POINTS(page.height),
    cssWidth: CM2PX(page.width),
    cssHeight: CM2PX(page.height),
});

export const migrateEditorData = (
    editorData: IEditorDataPage[] | IEditorData,
    savePhotos: (item: any) => void,
): IEditorData => {
    return [
        ...editorData.map((page) =>
            processPageItems(applyDimensionConversions(page), savePhotos!),
        ),
    ];
};

export const migrateEditorDataV2 = (
    editorData: IEditorDataPage[] | IEditorDataV2,
    savePhotos: (item: any) => void,
): IEditorDataV2 => {
    if (!Array.isArray(editorData)) {
        return {
            ...editorData,
            pages: editorData.pages.map((page) =>
                processPageItems(applyDimensionConversions(page), savePhotos!),
            ),
        };
    }

    const sharedWithAllPages: ISharedWithAllPages =
        editorData[0]?.sharedWithAllPages || {};

    return {
        pages: editorData.map((page) =>
            processPageItems(applyDimensionConversions(page), savePhotos!),
        ),
        ...sharedWithAllPages,
    };
};
